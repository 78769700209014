import {
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import React from 'react';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../../../../utils/Components/AbstractClass/AbstractClassTypes';
import { DescriptionsList } from '../../../../utils/Components/AbstractElement/AbstractElementTypes';
import { renderDate } from '../../../../utils/Base/helpers/renderDate';
import { areaAgccData } from '../../../Admin/Dictionaries/Extra/AreaExtra';
import { marksData } from '../../../Admin/Dictionaries/Extra/DictionariesData';
import { SearchFieldsType } from '../../../../utils/SearchPanel/types/SearchPanelTypes';
import { Tooltip } from 'antd';

export const renderTitleList = (item) => {
  if (!Array.isArray(item)) return undefined;
  const numberList = item.map((el) => +el.number).sort((a, b) => a - b);
  return item.length <= 3 ? (
    numberList.join('; ')
  ) : (
    <Tooltip title={numberList.join('; ')}>
      {numberList[0]} ... {numberList.at(-1)}
    </Tooltip>
  );
};
export const getInitialColumns = (t): AbstractColumns => [
  {
    title: t('Number'),
    key: 'number',
    dataIndex: 'number',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: t('aorpiDate'),
    key: 'aorpiDate',
    dataIndex: 'aorpiDate',
    align: 'center',
    sorter: true,
    visible: true,
    render: (item) => {
      return dayjs(item).format('DD.MM.YYYY');
    },
  },
  {
    title: t('aorpiTotalQuantity'),
    key: 'aorpiTotalQuantity',
    dataIndex: 'aorpiTotalQuantity',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: t('Product report status'),
    key: 'productReportStatuses',
    dataIndex: ['productReportStatuses', 'title'],
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: t('Title'),
    key: 'Title',
    dataIndex: ['titleListData'],
    align: 'center',
    visible: true,
    render: renderTitleList,
  },
  {
    title: t('Area'),
    key: 'area',
    dataIndex: ['areaData', 'title'],
    align: 'center',
    visible: true,
  },
  {
    title: t('Mark'),
    key: 'area',
    dataIndex: ['marksData', 'title'],
    align: 'center',
    visible: true,
  },
  {
    title: t('Contractor'),
    key: 'qcResponsibleId',
    dataIndex: ['qcResponsible', 'fullName'],
    align: 'center',
    visible: true,
  },
  {
    title: t('Controller'),
    key: 'constructionResponsibleId',
    dataIndex: ['customerResponsible', 'fullName'],
    align: 'center',
    visible: true,
  },
  {
    title: t('Customer'),
    key: 'customerResponsibleId',
    dataIndex: ['constructionResponsible', 'fullName'],
    align: 'center',
    visible: true,
  },
  {
    title: t('Description'),
    key: 'Description',
    dataIndex: ['description'],
    align: 'center',
    visible: true,
    ellipsis: true,
    render: (item) => item,
  },
];

export const getSearchFields = (t, statusesOptions): SearchFieldsType[] => [
  {
    component: 'Input',
    label: t('aorpi'),
    name: 'number',
    placeholder: '4.3.1.01.346',
    required: false,
    span: 4,
  },
  {
    component: 'DatePicker',
    label: t('aorpiDate'),
    name: 'aorpiDate',
    required: false,
    span: 4,
  },
  {
    component: 'InputNumber',
    label: t('aorpiTotalQuantity'),
    name: 'aorpiTotalQuantity',
    placeholder: '1.5',
    required: false,
    span: 4,
  },
  {
    component: 'select',
    label: t('Titles'),
    name: 'titleNumberList',
    placeholder: '',
    required: false,
    link: 'api/title?size=20&number=',
    dataListName: 'titleListDataList',
    showSearch: true,
    selectMode: 'multiple',
    selectItem: [
      {
        value: 'number',
        item: 'number',
      },
    ],
    span: 4,
  },
  {
    component: 'select',
    label: t('Areas'),
    name: 'areaTitleList',
    placeholder: '',
    required: false,
    link: areaAgccData.apiMethod + '?size=20&title=',
    dataListName: areaAgccData.dataListName,
    showSearch: true,
    selectMode: 'multiple',
    selectItem: [
      {
        value: areaAgccData.name,
        item: areaAgccData.name,
      },
    ],
    span: 4,
  },
  {
    component: 'select',
    label: t('Marks'),
    name: 'markTitleList',
    placeholder: '',
    required: false,
    link: marksData.apiMethod + '?size=20&title=',
    dataListName: marksData.dataListName,
    showSearch: true,
    selectMode: 'multiple',
    selectItem: [
      {
        value: marksData.name,
        item: marksData.name,
      },
    ],
    span: 4,
  },
  {
    component: 'input',
    label: t('Description'),
    name: 'description',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'select',
    label: t('Product report statuses'),
    name: 'productReportStatusIdList',
    placeholder: '',
    required: false,
    span: 4,
    selectItem: statusesOptions,
    selectMode: 'multiple',
  },
];

export const pageHeaderExtra: Array<AbstractClassPageHeaderExtraButtonItem> = [
  {
    type: 'primary',
    icon: <PlusOutlined />,
    onClick: {
      type: 'innerFunction',
      func: 'setIsAdd',
      extra: true,
    },
  },
];

export const getPopupList = (t: TFunction) => [
  {
    title: t('More details'),
    icon: <FileTextOutlined />,
    type: 'checkout',
    onClick: () => {
      console.log('Подробнее');
    },
  },
  {
    title: t('Edit'),
    icon: <EditOutlined />,
    type: 'edit',
    onClick: () => {
      console.log('Редактировать');
    },
  },
];

export const aorpiDescription: DescriptionsList = [
  {
    label: 'Number',
    fieldName: 'number',
    span: 6,
  },
  {
    label: 'Title',
    fieldName: 'titleListData',
    span: 4,
    render: renderTitleList,
  },
  {
    label: 'Area',
    fieldName: 'areaData',
    span: 4,
    render: (item) => item?.title,
  },
  {
    label: 'Mark',
    fieldName: 'marksData',
    span: 4,
    render: (item) => item?.title,
  },
  {
    label: 'aorpiDate',
    fieldName: 'aorpiDate',
    span: 2,
    render: renderDate,
  },
  {
    label: 'aorpiTotalQuantity',
    fieldName: 'aorpiTotalQuantity',
    span: 2,
  },
  {
    label: 'Product report status',
    fieldName: 'productReportStatuses',
    span: 2,
    render: (item) => item?.title,
  },
  {
    label: 'Contractor',
    fieldName: 'constructionResponsible.fullName',
    span: 2,
  },
  {
    label: 'Controller',
    fieldName: 'qcResponsible.fullName',
    span: 2,
  },
  {
    label: 'Customer',
    fieldName: 'customerResponsible.fullName',
    span: 2,
  },
  {
    label: 'Description',
    fieldName: 'description',
    span: 24,
  },
];
