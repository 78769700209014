import { t } from 'i18next';
import { WorkplaceMenuProps } from '../../LayoutTypes';
import React from 'react';
import {
  AppstoreAddOutlined,
  SettingFilled,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import NotificationLabel from '../Notificationlabel';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';

export const menuItemsAGCC = (
  props: WorkplaceMenuProps
): ItemType<MenuItemType>[] => {
  const { router, profile } = props;
  const navigate = router.navigate;
  return [
    {
      label: t('Entrance control'),
      key: 'vhodnoi-control',
      icon: <AppstoreAddOutlined />,
      permission: [
        'ProductReportController',
        'ConsumableController',
        'InputControlJournalController',
        'materialSupplyRequestStatusGetAll',
        'materialSupplyRequestConcreteController',
      ],
      children: [
        {
          label: t('Product report'),
          key: '/product-report',
          permission: ['ProductReportController'],
          onClick: () => {
            navigate('/agcc/product-report');
          },
        },
        {
          label: t('Pipeline'),
          key: '/pipeline',
          permission: ['ProductReportElementsController'],
          children: [
            {
              label: t('Product report elements'),
              key: '/product-report-element-pipeline',
              permission: ['ProductReportElementsController'],
              onClick: () => navigate('/agcc/pipeline-element-aorpi'),
            },
            {
              label: t('Consumables'),
              key: '/consumable-pipeline',
              permission: ['ProductReportElementsController'],
              onClick: () => navigate('/agcc/pipeline-consumable'),
            },
            {
              label: t('Distribution analytics product report elements'),
              key: '/product-report-elements-analytics',
              permission: [],
              onClick: () =>
                navigate('/agcc/product-report-elements-analytics'),
            },
          ],
        },
        {
          label: t('Pub. civil works'),
          key: 'Pub. civil works',
          permission: [
            'ProductReportResponsibleController',
            'ProductReportElementsController',
            'InputControlJournalController',
          ],
          children: [
            {
              label: t('Product report elements'),
              key: '/product-report-element',
              permission: ['ProductReportElementsController'],
              onClick: () => {
                navigate('/agcc/cvl-product-report-element');
              },
            },
            {
              label: t('Input control journals'),
              key: 'input-control-journal',
              permission: ['InputControlJournalController'],
              onClick: () => {
                navigate('/agcc/input-control-journal');
              },
            },
          ],
        },
        {
          label: t('Metal structures'),
          key: 'Metal structures',
          permission: ['ProductReportElementsController'],
          children: [
            {
              label: t('Product report elements'),
              key: '/metal-structures-product-report-element',
              permission: ['ProductReportElementsController'],
              onClick: () => {
                navigate('/agcc/metal-structures-product-report-element');
              },
            },
          ],
        },
        {
          label: t('Electrician'),
          key: '/ele',
          permission: ['ProductReportElementsController'],
          children: [
            {
              label: t('Product report elements'),
              key: '/ele-product-report-element-input',
              permission: ['ProductReportElementsController'],
              onClick: () => {
                navigate('/agcc/ele-product-report-element-input');
              },
            },
          ],
        },
        {
          label: t('Responsible_1'),
          key: '/aorpi-responsible',
          permission: ['ProductReportResponsibleController'],
          onClick: () => {
            navigate('/agcc/aorpi-responsible');
          },
        },
        {
          label: t('Requests'),
          key: '/aorpi-requests',
          permission: ['materialSupplyRequestConcreteController'],
          children: [
            {
              label: t('Requests of concrete'),
              key: '/material-supply-request-concrete',
              permission: ['materialSupplyRequestConcreteController'],
              onClick: () => {
                navigate('/agcc/material-supply-request-concrete');
              },
            },
            {
              label: t('Responsible_1'),
              key: '/material-supply-request-responsibles',
              permission: ['materialSupplyRequestConcreteController'],
              onClick: () => {
                navigate('/agcc/material-supply-request-responsibles');
              },
            },
          ],
        },
        {
          label: t('Other'),
          key: '/aorpi-others',
          permission: ['materialSupplyRequestStatusGetAll'],
          children: [
            {
              label: t('Request statuses'),
              key: '/material-supply-request-status',
              permission: ['materialSupplyRequestStatusGetAll'],
              onClick: () => {
                navigate('/agcc/material-supply-request-status');
              },
            },
          ],
        },
      ],
    },
    {
      label: t('Tech. data'),
      key: 'Tech. documentation',
      permission: ['TitleListController'],
      children: [
        {
          label: t('Titles'),
          key: '/titles',
          permission: ['TitleListController'],
          onClick: () => {
            navigate('/agcc/titles');
          },
        },
      ],
    },
    {
      label: t('RFI'),
      key: 'rfi',
      permission: ['RfiResponsibleController', 'RfiLogController'],
      children: [
        {
          label: t('LOG'),
          key: '/rfi-log',
          permission: ['RfiLogController'],
          onClick: () => {
            navigate('/agcc/rfi-log');
          },
        },
        {
          label: t('Responsible_1'),
          key: '/responsible',
          permission: ['RfiResponsibleController'],
          onClick: () => {
            navigate('/agcc/rfi-responsible');
          },
        },
      ],
    },
    {
      label: t('ADB LOG'),
      key: '/adb-log',
      permission: [],
      onClick: () => {
        navigate('/agcc/adb-log');
      },
    },
    {
      label: t('Pipeline'),
      key: 'pipeline',
      permission: [
        'PipingInstrumentationDiagramController',
        'IsoBondController',
        'ConstructionZoneController',
        'IsoListController',
        'PipelineEnvironmentController',
        'getAllWeldlog',
        'SpecificationController',
        'BasePhaseWeldlogGroupController',
        'TestPacketController',
        'SupportFollowUpController',
      ],
      children: [
        {
          label: t('TP'),
          key: 'tp',
          permission: ['IsoBondController', 'SupportFollowUpController'],
          children: [
            {
              label: t('BasePhase'),
              key: '/basephase-tp',
              permission: [],
              onClick: () => navigate('/agcc/basephase-tp'),
            },
            {
              label: t('BasePhase groups'),
              key: '/base-phase-weldlog-group',
              permission: ['BasePhaseWeldlogGroupController'],
              onClick: () => navigate('/agcc/base-phase-weldlog-group'),
            },
            {
              label: t('Support follow up'),
              key: '/support-follow-up',
              permission: ['SupportFollowUpController'],
              onClick: () => navigate('/agcc/support-follow-up'),
            },
          ],
        },
        {
          label: t('Title-zones'),
          key: '/title-construction-zones-bond',
          permission: ['TitleConstructionZoneBondController'],
          onClick: () => navigate('/agcc/title-construction-zones-bond'),
        },
        {
          label: t('Lines'),
          key: '/lines-tp',
          permission: [],
          onClick: () => navigate('/agcc/lines-tp'),
        },
        {
          label: t('Isometries'),
          key: '/isolist',
          permission: ['IsoListController'],
          onClick: () => navigate('/agcc/isolist'),
        },
        {
          label: t('Books'),
          key: '/book-pipeline',
          permission: [],
          onClick: () => navigate('/agcc/book-pipeline'),
        },
        {
          label: t('Isobond list'),
          key: '/isobond',
          permission: ['IsoBondController'],
          onClick: () => navigate('/agcc/isobond'),
        },
        {
          label: t('Test packs'),
          key: '/test-packs',
          permission: ['TestPacketController'],
          onClick: () => navigate('/agcc/test-packet'),
        },
        {
          label: t('Weldlog AGSP'),
          key: 'weldlog',
          permission: ['getAllWeldlog'],
          onClick: () => {
            navigate('/agcc/weldlog-agsp');
          },
        },
        {
          label: t('Weldlog analytics by ItemCode'),
          key: 'weldlog-analytics',
          permission: ['getAllWeldlog'],
          onClick: () => {
            navigate('/agcc/weldlog-analytics');
          },
        },
        {
          label: t('Specifications'),
          key: '/specification',
          permission: ['SpecificationController'],
          onClick: () => navigate('/agcc/specification'),
        },
        {
          label: t('Other'),
          key: 'other',
          permission: [
            'PipingInstrumentationDiagramController',
            'ConstructionZoneController',
            'PipelineEnvironmentController',
            'getAllWeldingCard',
            'getAllSteelType',
            'getAllDetailType',
            'getAllConsumableType',
          ],
          children: [
            {
              label: t('Technological cards'),
              key: 'techCard',
              permission: [
                'getAllWeldingCard',
                'getAllSteelType',
                'getAllDetailType',
                'getAllConsumableType',
              ],
              children: [
                {
                  label: t('List of technological cards'),
                  key: '/welding-card',
                  permission: ['getAllWeldingCard'],
                  onClick: () => {
                    navigate('/agcc/welding-card');
                  },
                },
                {
                  label: t('Steel types'),
                  key: '/steel-type',
                  permission: ['getAllSteelType'],
                  onClick: () => {
                    navigate('/agcc/steel-type');
                  },
                },
                {
                  label: t('Detail types'),
                  key: '/detail-type',
                  permission: ['getAllDetailType'],
                  onClick: () => {
                    navigate('/agcc/detail-type');
                  },
                },
                {
                  label: t('Consumable types'),
                  key: '/consumable-type',
                  permission: ['getAllConsumableType'],
                  onClick: () => {
                    navigate('/agcc/consumable-type');
                  },
                },
              ],
            },
            {
              label: t('Welders'),
              key: '/welder',
              permission: ['IsoListController'],
              onClick: () => navigate('/agcc/welder'),
            },
            {
              label: t('Responsible_1'),
              key: '/pipeline-responsible',
              permission: ['IsoListController'],
              onClick: () => navigate('/agcc/pipeline-responsible'),
            },
            {
              label: t('Groups of technical devices'),
              key: '/groupTechDevices',
              children: [
                {
                  label: t('Handling equipment'),
                  key: '/lifting-transport-equipment',
                  onClick: () => {
                    navigate('/agcc/lifting-transport-equipment');
                  },
                },
                {
                  label: t('Boiler equipments'),
                  key: '/boiler-equipment',
                  onClick: () => {
                    navigate('/agcc/boiler-equipment');
                  },
                },
                {
                  label: t('Gas equipments'),
                  key: '/gas-equipment',
                  onClick: () => {
                    navigate('/agcc/gas-equipment');
                  },
                },
                {
                  label: t('Oil and gas production equipments'),
                  key: '/oil-gas-equipment',
                  onClick: () => {
                    navigate('/agcc/oil-gas-equipment');
                  },
                },
                {
                  label: t('Metallurgical equipments'),
                  key: '/metallurgical-equipment',
                  onClick: () => {
                    navigate('/agcc/metallurgical-equipment');
                  },
                },
                {
                  label: t(
                    'Equipments for chemical, petrochemical, oil refining and explosion-hazardous industries'
                  ),
                  key: '/chemical-petrochemical-equipment',
                  onClick: () => {
                    navigate('/agcc/chemical-petrochemical-equipment');
                  },
                },
                {
                  label: t('Mining equipments'),
                  key: '/mining-equipment',
                  onClick: () => {
                    navigate('/agcc/mining-equipment');
                  },
                },
                {
                  label: t(
                    'Equipments for the transportation of dangerous goods'
                  ),
                  key: '/transportation-equipment',
                  onClick: () => {
                    navigate('/agcc/transportation-equipment');
                  },
                },
                {
                  label: t('Building construction'),
                  key: '/building-structures',
                  onClick: () => {
                    navigate('/agcc/building-structures');
                  },
                },
                {
                  label: t('Steel bridge structures'),
                  key: '/steel-bridge-structures',
                  onClick: () => {
                    navigate('/agcc/steel-bridge-structures');
                  },
                },
              ],
            },
            {
              label: t('Welding locations'),
              key: '/welding-locations',
              onClick: () => {
                navigate('/agcc/welding-locations');
              },
            },
            {
              label: t('Welding methods'),
              key: '/welding-methods',
              onClick: () => {
                navigate('/agcc/welding-method');
              },
            },
            {
              label: t('Joint types'),
              key: '/joint-types',
              onClick: () => {
                navigate('/agcc/joint-types');
              },
            },
            {
              label: t('Element types'),
              key: '/element-types',
              onClick: () => {
                navigate('/agcc/element-types');
              },
            },
            {
              label: t('Inspectors'),
              key: '/qc-inspector',
              onClick: () => {
                navigate('/agcc/qc-inspector');
              },
            },
            {
              label: t('Pipeline materials'),
              key: '/pipeline-materials',
              onClick: () => {
                navigate('/agcc/pipeline-materials');
              },
            },
            {
              label: t('Pipeline category'),
              key: '/pipeline-category',
              onClick: () => {
                navigate('/agcc/pipeline-category');
              },
            },
            {
              label: t('Pipeline environments'),
              key: '/pipeline-environment',
              permission: ['PipelineEnvironmentController'],
              onClick: () => navigate('/agcc/pipeline-environment'),
            },
            {
              label: t('Pipeline equipment diagrams'),
              key: '/piping-instrumentation-diagram',
              permission: ['PipingInstrumentationDiagramController'],
              onClick: () => navigate('/agcc/piping-instrumentation-diagram'),
            },
            {
              label: t('Construction zones'),
              key: '/construction-zones',
              permission: ['ConstructionZoneController'],
              onClick: () => navigate('/agcc/construction-zones'),
            },
            {
              label: t('Statuses book'),
              key: '/statsuses-book',
              permission: [],
              onClick: () => navigate('/agcc/book-status-pipeline'),
            },
          ],
        },
      ],
    },
    {
      label: t('Reporting on the volume of work'),
      key: 'workingAmountResult',
      permission: ['WorkingTypesBondController', 'WorkingAmountsController'],
      children: [
        {
          label: t('Titles of works'),
          key: '/working-types-bond',
          permission: ['WorkingTypesBondController'],
          onClick: () => {
            navigate('/agcc/working-types-bond');
          },
        },
        {
          label: t('Statistic'),
          key: '/working-amounts',
          permission: ['WorkingAmountsController'],
          onClick: () => {
            navigate(
              `/agcc/working-amounts?page=0&size=10&yearWeekList=${dayjs().isoWeek()}&yearList=${dayjs().get(
                'year'
              )}&sort=constructionWorksAmountPercentage%2Cdesc`
            );
          },
        },
      ],
    },
    {
      label: t('Electrician'),
      key: 'ew',
      permission: [
        'EleEngineersController',
        'EleUnitPriceController',
        'EleSpecificationBondController',
        'EleBasePhaseController',
        'EleExecutiveController',
        'EleTitleMarkController',
        'EleExecutiveFormNumberController',
        'ProductReportElementsController',
      ],
      children: [
        {
          label: t('Database'),
          key: '/ew-base-phase',
          permission: ['EleBasePhaseController'],
          onClick: () => {
            navigate('/agcc/ew-base-phase');
          },
        },
        {
          label: t('Inspections'),
          key: '/ele-rfi',
          permission: [],
          onClick: () => {
            navigate('/agcc/ele-rfi-log');
          },
        },
        {
          label: t('Executive documentation'),
          key: '/ele-executive',
          permission: ['EleExecutiveController'],
          onClick: () => {
            navigate('/agcc/ele-executive');
          },
        },
        {
          label: t('Title-marks'),
          key: '/ele-title-marks',
          permission: ['EleTitleMarkController'],
          onClick: () => {
            navigate('/agcc/ele-title-marks');
          },
        },
        {
          label: t('Engineers'),
          key: '/ele-engineers',
          permission: ['EleEngineersController'],
          onClick: () => {
            navigate('/agcc/ele-engineers');
          },
        },
        {
          label: t('Working documentation'),
          key: '/ew-working-documentation-all',
          permission: ['EleSpecificationBondController'],
          children: [
            {
              label: t('Specifications'),
              key: '/ew-specification',
              permission: ['EleSpecificationBondController'],
              onClick: () => {
                navigate('/agcc/ew-specification');
              },
            },
            {
              label: t('Plans and journals'),
              key: '/ew-working-documentation',
              permission: ['EleSpecificationBondController'],
              onClick: () => {
                navigate('/agcc/ew-working-documentation');
              },
            },
            {
              label: t('Relationship between plans and specifications'),
              key: '/specification-bond',
              permission: ['EleSpecificationBondController'],
              onClick: () => {
                navigate('/agcc/specification-bond');
              },
            },
          ],
        },
        {
          label: t('Unit price'),
          key: 'unit-price',
          permission: ['EleUnitPriceController'],
          onClick: () => {
            navigate('/agcc/unit-price');
          },
        },
        {
          label: t('Summary information'),
          key: 'ew-summary',
          permission: ['EleUnitPriceController'],
          onClick: () => {
            navigate('/agcc/ew-summary');
          },
        },
        {
          label: t('Other'),
          key: 'ew-other',
          permission: ['EleExecutiveFormNumberController'],
          children: [
            {
              label: t('Types of work'),
              key: 'working-categories',
              permission: [],
              onClick: () => {
                navigate('/agcc/working-categories');
              },
            },
            {
              label: t('Executive documentation form'),
              key: 'ele-ed-forms',
              permission: ['EleExecutiveFormNumberController'],
              onClick: () => {
                navigate('/agcc/ele-ed-forms');
              },
            },
            {
              label: t('Comments on inspections'),
              key: 'ele-rfi-comment',
              permission: ['EleExecutiveFormNumberController'],
              onClick: () => {
                navigate('/agcc/ele-rfi-comment');
              },
            },
            {
              label: t('ED Location'),
              key: 'ele-locations',
              permission: [],
              onClick: () => {
                navigate('/agcc/ele-location');
              },
            },
            {
              label: t('ED Status'),
              key: 'ele-statuses',
              permission: [],
              onClick: () => {
                navigate('/agcc/ele-status');
              },
            },
          ],
        },
      ],
    },
    {
      label: t('Dictionaries'),
      key: 'dictionaries',
      children: [
        {
          label: t('Units of measure'),
          key: '/measurement-unit',
          onClick: () => {
            navigate('/agcc/measurement-unit');
          },
        },
        {
          label: t('Factory numbers'),
          key: '/factory-numbers',
          onClick: () => {
            navigate('/agcc/factory-numbers');
          },
        },
        {
          label: t('Temperature'),
          key: '/temperature',
          onClick: () => {
            navigate('/agcc/temperature');
          },
        },
        {
          label: t('Marks'),
          key: '/marks',
          onClick: () => {
            navigate('/agcc/marks');
          },
        },
        {
          label: t('Area'),
          key: '/area',
          onClick: () => {
            navigate('/agcc/area');
          },
        },
        {
          label: t('Installations'),
          key: '/installations',
          onClick: () => {
            navigate('/agcc/installations');
          },
        },
        {
          label: t('Product report statuses'),
          key: '/product-report-statuses',
          onClick: () => {
            navigate('/agcc/product-report-statuses');
          },
        },
        {
          label: t('Input control journal statuses'),
          key: '/input-control-journal-statuses',
          onClick: () => {
            navigate('/agcc/input-control-journal-statuses');
          },
        },
        {
          label: t('Titles of works'),
          key: '/works-names',
          onClick: () => {
            navigate('/agcc/works-names');
          },
        },
        {
          label: t('RFI'),
          key: '/rfi',
          children: [
            {
              label: t('Types of work'),
              key: '/types-of-work',
              onClick: () => {
                navigate('/agcc/rfi-types-of-work');
              },
            },
            {
              label: t('Types and methods of control'),
              key: '/rfi-types-of-quality-control',
              onClick: () => {
                navigate('/agcc/rfi-types-of-quality-control');
              },
            },
            {
              label: t('Methods of control'),
              key: '/rfi-types-of-control',
              onClick: () => {
                navigate('/agcc/rfi-types-of-control');
              },
            },
            {
              label: t('Scope of control'),
              key: '/rfi-scope-of-control',
              onClick: () => {
                navigate('/agcc/rfi-scope-of-control');
              },
            },
            {
              label: t('RFI results'),
              key: '/rfi-result',
              onClick: () => {
                navigate('/agcc/rfi-result');
              },
            },
            {
              label: t('Kinds of control'),
              key: '/rfi-kind-of-control',
              onClick: () => {
                navigate('/agcc/rfi-kind-of-control');
              },
            },
          ],
        },
        {
          label: t('ADB LOG'),
          key: '/adb-log-dictionaries',
          children: [
            {
              label: t('Directions of work'),
              key: '/direction-of-work',
              onClick: () => navigate('/agcc/direction-of-work'),
            },
            {
              label: t('Disciplines'),
              key: '/discipline-of-work',
              onClick: () => navigate('/agcc/discipline-of-work'),
            },
            {
              label: t('Sub Disciplines'),
              key: '/sub-discipline-of-work',
              onClick: () => navigate('/agcc/sub-discipline-of-work'),
            },
            {
              label: t('Groups of block factors'),
              key: '/group-of-block-factor',
              onClick: () => navigate('/agcc/group-of-block-factor'),
            },
          ],
        },
      ],
    },
    {
      key: '/regulations',
      label: t('Regulatory documents'),
      onClick: () => navigate('/agcc/regulations'),
    },
    {
      label: t('Admin panel'),
      key: '/adminPanel',
      icon: <SettingOutlined />,
      children: [
        {
          label: t('Positions'),
          key: '/positions',
          onClick: () => {
            navigate('/agcc/positions');
          },
        },
        {
          label: t('Departments'),
          key: '/departments',
          onClick: () => {
            navigate('/agcc/departments');
          },
        },
        {
          label: t('Organizations'),
          key: '/organizations',
          onClick: () => {
            navigate('/agcc/organizations');
          },
        },
        {
          label: t('Organization types'),
          key: '/types',
          onClick: () => {
            navigate('/agcc/types');
          },
        },
        {
          label: t('Accounts'),
          key: '/accounts',
          onClick: () => {
            navigate('/agcc/accounts');
          },
        },
      ],
    },
    profile?.admin && {
      label: 'Super ' + t('Admin panel'),
      key: '/superAdmin',
      icon: <SettingFilled />,
      children: [
        {
          label: t('Permissions'),
          key: '/permissions',
          onClick: () => {
            navigate('/agcc/permissions');
          },
        },
      ],
    },
    {
      label: (
        <>
          {t('Profile')} <NotificationLabel />
        </>
      ),
      key: '/profile',
      icon: <UserOutlined />,
      onClick: () => {
        navigate('/agcc/profile');
      },
    },
  ];
};
