import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';
import React from 'react';
import PipingInstrumentationDiagramForm from '../Components/PipingInstrumentationDiagramForm';
import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';

const idName = 'id';
const api = 'api/piping-instrumentation-diagram';
const name = 'number';

export const getInitialColumns = (t): AbstractColumns => [
  {
    title: t('Number'),
    dataIndex: 'number',
    key: 'number',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

export const getSearchFields = (t) => [
  {
    component: 'Input',
    label: t('Number'),
    name: 'number',
    placeholder: '',
    required: false,
    span: 4,
  },
];

export const pipingInstrumentationDiagramData: DictionaryData = {
  apiMethod: api,
  dataListName: 'pidDataList',
  idName: idName,
  pageHeaderTitle: 'Pipeline equipment diagrams',
  tabNameField: name,
  columnsName: 'PipingInstrumentationDiagramTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupListDeleteId,
  addComponent: <PipingInstrumentationDiagramForm type={'add'} />,
  editComponent: <PipingInstrumentationDiagramForm type={'edit'} />,
};

export const oncePipingInstrumentationDiagramProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'pidData',
  name: name,
  descriptions: [{ label: 'Number', fieldName: 'number', span: 4 }],
  editComponent: <PipingInstrumentationDiagramForm type={'edit'} />,
};
