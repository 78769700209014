import { io } from 'socket.io-client';
import { KeycloakApp } from '../Keycloak/Keycloak';
import jwtDecode from 'jwt-decode';

const project = localStorage.getItem('project');

const url =
  project === 'agcc'
    ? `${process.env.REACT_APP_SOCKET_SERVER_URL_AGCC}`
    : `${process.env.REACT_APP_SOCKET_SERVER_URL}`;

export let socket;

export const initSocket = () => {
  socket = io(url, {
    query: {
      name: jwtDecode<any>(KeycloakApp.token!).preferred_username,
      token: `Bearer ${KeycloakApp.token}`,
    },
  });
};
