import React from 'react';
import { Button, Flex, Layout, Popover, Select, Space, Tooltip } from 'antd';
import { HeartTwoTone } from '@ant-design/icons';
import {
  MdOutlineAutoAwesome,
  MdOutlineDarkMode,
  MdOutlineWbSunny,
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setDarkTheme as setDarkThemeReducer } from '../../../store/reducers/AuthReducer';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';

const { Footer: FooterA } = Layout;

const Footer = React.memo(
  ({
    setDarkTheme,
    darkTheme,
  }: {
    setDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
    darkTheme: boolean;
  }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onChangeTheme = (value: boolean) => {
      setDarkTheme(value);
      dispatch(setDarkThemeReducer(value));
      value
        ? localStorage.setItem('darkTheme', 'true')
        : localStorage.removeItem('darkTheme');
    };

    const getProjectOptions = () => {
      const options: { value: string; label: string }[] = [];
      if (KeycloakApp?.hasRealmRole('realm-role-agpp')) {
        options.push({ value: 'agpp', label: t('AGPP') });
      }
      if (KeycloakApp?.hasRealmRole('realm-role-agcc')) {
        options.push({ value: 'agcc', label: t('AGCC') });
      }

      return options;
    };

    const size = 14;

    return (
      <FooterA>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Flex gap={16} align={'center'}>
            <div>
              Made with <HeartTwoTone twoToneColor="#eb2f96" /> by YAMATA
            </div>
          </Flex>
          <Space>
            <Select
              defaultValue={localStorage.getItem('project')}
              onChange={(value) => {
                localStorage.setItem('project', value);
                window.location.replace('/');
              }}
              size={'small'}
              options={getProjectOptions()}
            />
            <Popover
              arrow={false}
              content={
                <Space direction={'vertical'} size={'small'}>
                  <Tooltip title={t('Change theme')} placement={'left'}>
                    <Button
                      type={'text'}
                      onClick={() => onChangeTheme(!darkTheme)}
                      shape={'circle'}
                      style={{ fontSize: size }}
                      icon={
                        darkTheme ? (
                          <MdOutlineWbSunny size={14} />
                        ) : (
                          <MdOutlineDarkMode size={14} />
                        )
                      }
                    />
                  </Tooltip>
                </Space>
              }
            >
              <Button
                type={'text'}
                shape={'circle'}
                style={{ fontSize: size }}
                icon={<MdOutlineAutoAwesome size={size} />}
              />
            </Popover>
          </Space>
        </div>
      </FooterA>
    );
  }
);
Footer.displayName = 'Footer';
export default Footer;
