import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../../../../utils/Components/AbstractClass/AbstractClassTypes';

export const getInitialColumns = (t) => {
  const initialColumns: AbstractColumns = [
    {
      title: t('Number'),
      key: 'number',
      dataIndex: 'number',
      align: 'center',
      sorter: true,
      visible: true,
    },
    {
      title: t('Name_1'),
      key: 'titleName',
      dataIndex: 'titleName',
      align: 'center',
      sorter: true,
      visible: true,
    },
    {
      title: t('Operational complex'),
      key: 'operationalComplex',
      dataIndex: 'operationalComplex',
      align: 'center',
      sorter: true,
      visible: true,
    },
    {
      title: t('Launch complex'),
      key: 'launchComplex',
      dataIndex: 'launchComplex',
      align: 'center',
      sorter: true,
      visible: true,
    },
    {
      title: t('Title object'),
      key: 'titleObject',
      dataIndex: 'titleObject',
      align: 'center',
      sorter: true,
      visible: true,
    },
  ];
  return initialColumns;
};

export const getSearchFields = (t) => [
  {
    component: 'Input',
    label: t('Number'),
    name: 'number',
    placeholder: '3699',
    required: false,
    span: 4,
  },
];

export const getPageHeaderExtra = (
  t
): Array<AbstractClassPageHeaderExtraButtonItem> => [
  {
    type: 'primary',
    icon: <PlusOutlined />,
    title: t('Add'),
    onClick: {
      type: 'innerFunction',
      func: 'setIsAdd',
      extra: true,
    },
  },
];
