import {
  AppstoreAddOutlined,
  BulbOutlined,
  SettingFilled,
  UserOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';
import { t } from 'i18next';
import { WorkplaceMenuProps } from '../../LayoutTypes';
import NotificationLabel from '../Notificationlabel';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';

export const menuItems = (
  props: WorkplaceMenuProps,
  state
): ItemType<MenuItemType>[] => {
  const department =
    props.profile?.organizationPositionData?.organizationDepartmentData?.title;
  const { isNew, updateHistoryContent } = state;
  const { profile, router } = props;
  const navigate = router.navigate;
  const items = updateHistoryContent.map((update) => {
    return {
      key: update.updateHistoryId,
      permission: [],
      label: (
        <span style={{ fontWeight: update.isView ? 400 : 600 }}>
          {update.title}
        </span>
      ),
      onClick: () => navigate(`/agpp/updates/${update.updateHistoryId}`),
    };
  });
  items?.push({
    key: 'last',
    label: t('See all entries'),
    danger: true,
    permission: [],
    onClick: () => navigate('/agpp/updates'),
  });
  return [
    {
      label: t('Entrance control'),
      key: 'vhodnoi-control',
      icon: <AppstoreAddOutlined />,
      children: [
        {
          label: t('Product report'),
          key: '/product-report',
          permission: ['ProductReportController'],
          onClick: () => {
            navigate('/agpp/product-report');
          },
        },
        {
          label: t('Product report elements'),
          key: '/product-report-element',
          permission: ['ProductReportController'],
          onClick: () => {
            navigate('/agpp/product-report-element');
          },
        },
        {
          label: t('Consumables'),
          key: '/consumables',
          permission: ['ConsumableController'],
          onClick: () => {
            navigate('/agpp/consumables');
          },
        },
      ],
      permission: ['ProductReportController', 'ConsumableController'],
    },
    {
      label: t('Tech. data'),
      key: 'Tech. documentation',
      permission: [
        'LineListController',
        'IsoListController',
        'TitleListController',
        'BasePhaseController',
      ],
      children: [
        {
          label: t('Lines'),
          key: '/linelist',
          permission: ['LineListController'],
          onClick: () => {
            navigate('/agpp/linelist');
          },
        },
        {
          label: t('Isometries'),
          key: '/isolist',
          permission: ['IsoListController'],
          onClick: () => {
            navigate('/agpp/isolist');
          },
        },
        {
          label: t('Titles'),
          key: '/titles',
          permission: ['TitleListController'],
          onClick: () => {
            navigate('/agpp/titles');
          },
        },
        {
          label: 'BasePhase',
          key: '/basephase',
          permission: ['BasePhaseController'],
          onClick: () => {
            navigate('/agpp/basephase');
          },
        },
      ],
    },
    {
      label: t('Welders'),
      key: '/welders',
      permission: ['WelderController'],
      onClick: () => {
        navigate('/agpp/welders');
      },
    },
    {
      label: t('WLG & QC'),
      key: '/wdbQc',
      permission: [
        'WdbController',
        'WeldlogController',
        'WelderJoinsStatusController',
      ],
      children: [
        {
          label: t('WDB'),
          key: '/wdb',
          permission: ['WdbController'],
          onClick: () => {
            navigate('/agpp/wdb');
          },
        },
        {
          label: t('WLG'),
          permission: ['WdbController'],
          key: '/wlg',
          onClick: () => {
            navigate('/agpp/wlg');
          },
        },
        {
          label: t('Weldlog'),
          key: '/weldlog',
          permission: ['WeldlogController'],
          onClick: () => {
            navigate('/agpp/weldlog');
          },
        },
        {
          label: t('Welder on joint status'),
          permission: ['WelderJoinsStatusController'],
          key: '/welder-status',
          onClick: () => {
            navigate('/agpp/welder-on-joint-status');
          },
        },
      ],
    },
    {
      label: t('NDT'),
      key: '/ndt-main',
      permission: ['NDTController', 'LabRequestController'],
      children: [
        {
          label: t('Statistics') + ' NDT',
          key: '/ndt',
          onClick: () => {
            navigate('/agpp/ndt');
          },
          permission: ['NDTController'],
        },
        {
          label: t('Requests'),
          key: '/lab-request',
          onClick: () => {
            navigate('/agpp/ndt-lab-requests');
          },
          permission: ['LabRequestController'],
        },
      ],
    },
    {
      label: t('Laboratories'),
      key: '/laboratories',
      permission: [
        'LabRequestController',
        'LabsEmployeesController',
        'LabEquipmentsController',
      ],
      children: [
        {
          label: t('Employees'),
          key: '/lab-employees',
          onClick: () => {
            navigate('/agpp/lab-employees');
          },
          permission: ['LabsEmployeesController'],
        },
        {
          label: t('Equipments'),
          key: '/lab-equipments',
          onClick: () => {
            navigate('/agpp/lab-equipments');
          },
          permission: ['LabEquipmentsController'],
        },
        {
          label: t('Requests'),
          key: '/lab-requests',
          onClick: () => {
            navigate('/agpp/lab-requests');
          },
          permission: ['LabRequestController'],
        },
      ],
    },
    {
      label: t('Executive documentation'),
      key: 'Executive documentation',
      children: [
        {
          label: t('Specifications'),
          key: '/specifications',
          permission: ['SpecificationController'],
          onClick: () => {
            navigate('/agpp/specifications');
          },
        },
        {
          label: t('Test packs'),
          key: '/testpacks',
          permission: ['TestPacketController'],
          onClick: () => {
            navigate('/agpp/testpacks');
          },
        },
        {
          label: t('Passports'),
          key: '/passports',
          permission: ['PassportController'],
          onClick: () => {
            navigate('/agpp/passports');
          },
        },
        {
          label: t('Specifications for passports'),
          key: '/specification-for-passport',
          permission: ['SpecificationForPassportController'],
          onClick: () => {
            navigate('/agpp/specification-for-passport');
          },
        },
      ],
      permission: [
        'SpecificationController',
        'TestPacketController',
        'PassportController',
        'SpecificationForPassportController',
      ],
    },
    {
      label: t('Electrician'),
      key: '/electrician',
      permission: [
        'TitleMarksController',
        'FolderController',
        'EwBasePhaseController',
        'AreaController',
        'getFolderLocationDataList',
        'getFoldersStatusDataList',
        'getEwKqDataList',
        'getMarksDataList',
        'getEwFoldersBlockFactorsReferenceDataList',
      ],
      children: [
        {
          label: t('Summary information'),
          key: '/ew-summary-information',
          permission: [],
          onClick: () => {
            navigate('/agpp/ew-summary-information');
          },
        },
        {
          label: t('Folders'),
          key: '/ew-folders',
          permission: ['FolderController'],
          onClick: () => {
            navigate('/agpp/ew-folders');
          },
        },
        {
          label: t('Database'),
          key: '/ew-base-phase',
          permission: ['EwBasePhaseController'],
          onClick: () => {
            navigate('/agpp/ew-base-phase');
          },
        },
        {
          label: t('Inspections'),
          key: '/ew-rfi',
          permission: ['EwBasePhaseController'],
          onClick: () => {
            navigate('/agpp/ew-rfi');
          },
        },
        {
          label: t('Other'),
          key: '/ew-other',
          permission: [
            'AreaController',
            'TitleMarksController',
            'getEwRfiCommentDataList',
          ],
          children: [
            {
              label: t('Marks'),
              key: '/ew-marks',
              permission: ['getMarksDataList'],
              onClick: () => {
                navigate('/agpp/ew-marks');
              },
            },
            {
              label: t('Title-marks'),
              key: '/electrician-title-mark',
              permission: ['TitleMarksController'],
              onClick: () => {
                navigate('/agpp/ew-title-mark');
              },
            },
            {
              label: t('Folder statuses'),
              key: '/folder-statuses',
              permission: ['getFoldersStatusDataList'],
              onClick: () => {
                navigate('/agpp/ew-folder-statuses');
              },
            },
            {
              label: t('KQ'),
              key: '/ewkq',
              permission: ['getEwKqDataList'],
              onClick: () => {
                navigate('/agpp/ewkq');
              },
            },
            {
              label: t('Folders location'),
              key: '/location',
              permission: ['getFolderLocationDataList'],
              onClick: () => {
                navigate('/agpp/folder-location');
              },
            },
            {
              label: t('Block factors'),
              key: '/ew-folders-block-factors-reference',
              permission: ['getEwFoldersBlockFactorsReferenceDataList'],
              onClick: () => {
                navigate('/agpp/ew-folders-block-factors-reference');
              },
            },
            {
              label: t('Areas'),
              key: '/ew-areas',
              onClick: () => {
                navigate('/agpp/ew-area');
              },
              permission: ['AreaController'],
            },
            {
              label: t('Comments on inspections'),
              key: '/ew-rfi-comment',
              onClick: () => {
                navigate('/agpp/ew-rfi-comment');
              },
              permission: ['getEwRfiCommentDataList'],
            },
            {
              label: t('Folder Status Statuses'),
              key: '/status-of-folder-statuses',
              onClick: () => {
                navigate('/agpp/status-of-folder-statuses');
              },
              permission: ['getAllStatusOfFolderStatusesData'],
            },
          ],
        },
      ],
    },
    {
      label: t('Dictionaries'),
      key: 'dictionaries',
      permission: [
        'getFactoryNumberDataList',
        'getFolderLocationDataList',
        'getElementTypeDataList',
        'getMeasurementUnitDataList',
        'getPipingCategoryDataList',
        'getTestPacketStatusesDataList',
        'getTestPacketAccountStatusesDataList',
        'getMarksDataList',
        'getFoldersStatusDataList',
        'getFolderLocationDataList',
        'getEwKqDataList',
        'getLabsControlTypesDataList',
        'getEwFoldersBlockFactorsReferenceDataList',
        'getLabsControlKindDataList',
        'getReportsTypeOfWorksDataList',
        'getConstructionDirectionDataList',
      ],
      children: [
        {
          label: t('Units of measure'),
          key: '/measurement-unit',
          permission: ['getMeasurementUnitDataList'],
          onClick: () => {
            navigate('/agpp/measurement-unit');
          },
        },
        {
          label: t('Joints'),
          key: '/joints',
          permission: [],
          children: [
            {
              label: t('Welding locations'),
              key: '/welding-locations',
              onClick: () => {
                navigate('/agpp/welding-locations');
              },
            },
            {
              label: t('Welding methods'),
              key: '/welding-methods',
              onClick: () => {
                navigate('/agpp/welding-methods');
              },
            },
            {
              label: t('Joint types'),
              key: '/joint-types',
              onClick: () => {
                navigate('/agpp/joint-types');
              },
            },
          ],
        },
        {
          label: t('Pipeline'),
          key: '/pipeline',
          permission: ['getPipingCategoryDataList'],
          children: [
            {
              label: t('Pipeline materials'),
              key: '/pipeline-materials',
              onClick: () => {
                navigate('/agpp/pipeline-materials');
              },
            },
            {
              label: t('Pipeline category'),
              key: '/pipeline-category',
              permission: ['getPipingCategoryDataList'],
              onClick: () => {
                navigate('/agpp/pipeline-category');
              },
            },
            {
              label: t('Welding position'),
              key: '/welding-position',
              onClick: () => {
                navigate('/agpp/welding-position');
              },
            },
            {
              label: t('PWHT operators'),
              key: '/pwhtoperator',
              onClick: () => {
                navigate('/agpp/pwhtoperator');
              },
            },
          ],
        },
        {
          label: t('Inspectors') + ' QC',
          key: '/inspectors',
          onClick: () => {
            navigate('/agpp/inspectors');
          },
        },
        {
          label: t('Factory numbers'),
          key: '/factory-numbers',
          permission: ['getFactoryNumberDataList'],
          onClick: () => {
            navigate('/agpp/factory-numbers');
          },
        },
        {
          label: t('Temperature'),
          key: '/temperature',
          onClick: () => {
            navigate('/agpp/temperature');
          },
        },
        {
          label: t('Installations'),
          key: '/installations',
          onClick: () => {
            navigate('/agpp/installations');
          },
        },
        {
          label: t('Test packet statuses'),
          key: '/test-packet-statuses',
          permission: ['getTestPacketStatusesDataList'],
          onClick: () => {
            navigate('/agpp/test-packet-statuses');
          },
        },
        {
          label: t('Test packet account statuses'),
          key: '/test-packet-account-statuses',
          permission: ['getTestPacketAccountStatusesDataList'],
          onClick: () => {
            navigate('/agpp/test-packet-account-statuses');
          },
        },
        {
          label: t('Type of works'),
          key: '/reports-type-of-works',
          permission: ['getReportsTypeOfWorksDataList'],
          onClick: () => {
            navigate('/agpp/reports-type-of-works');
          },
        },
        {
          label: t('Construction directions'),
          key: '/construction-directions',
          permission: ['getConstructionDirectionData'],
          onClick: () => {
            navigate('/agpp/construction-direction');
          },
        },
        {
          label: t('Laboratories'),
          key: '/labs',
          permission: [
            'getLabsControlTypesDataList',
            'getLabsControlKindDataList',
          ],
          children: [
            {
              label: t('Types of laboratory controls'),
              key: '/labs-control-types',
              permission: ['getLabsControlKindDataList'],
              onClick: () => {
                navigate('/agpp/labs-control-types');
              },
            },
            {
              label: t('Types of laboratory'),
              key: '/lab-types',
              permission: ['getLabsControlTypesDataList'],
              onClick: () => {
                navigate('/agpp/lab-types');
              },
            },
          ],
        },
      ],
    },
    department?.toLowerCase().includes('software') && {
      label: t('Admin panel'),
      key: '/adminPanel',
      children: [
        {
          label: t('Positions'),
          key: '/positions',
          onClick: () => {
            navigate('/agpp/positions');
          },
        },
        {
          label: t('Departments'),
          key: '/departments',
          onClick: () => {
            navigate('/agpp/departments');
          },
        },
        {
          label: t('Organizations'),
          key: '/organizations',
          onClick: () => {
            navigate('/agpp/organizations');
          },
        },
        {
          label: t('Organization types'),
          key: '/types',
          onClick: () => {
            navigate('/agpp/types');
          },
        },
        {
          label: t('Accounts'),
          key: '/accounts',
          onClick: () => {
            navigate('/agpp/accounts');
          },
        },
      ],
    },
    profile &&
      profile.admin && {
        label: 'Super ' + t('Admin panel'),
        key: '/superAdmin',
        icon: <SettingFilled />,
        children: [
          {
            label: t('Permissions'),
            key: '/permissions',
            onClick: () => {
              navigate('/agpp/permissions');
            },
          },
          {
            label: t('Element types'),
            key: '/element-types',
            onClick: () => {
              navigate('/agpp/element-types');
            },
          },
          {
            label: t('Type of support'),
            key: '/support-types',
            onClick: () => {
              navigate('/agpp/support-types');
            },
          },
          {
            label: t('Updates'),
            key: '/updates',
            onClick: () => {
              navigate('/agpp/updates');
            },
          },
        ],
      },
    {
      label: t('Updates'),
      theme: 'light',
      children: items,
      icon: (
        <Badge
          count={isNew ? 'new' : undefined}
          style={{
            padding: 0,
            fontSize: 9,
            height: 10,
            color: 'white',
            position: 'relative',
            top: 0,
            right: 15,
          }}
        >
          <BulbOutlined />
        </Badge>
      ),
      key: '/history',
    },
    {
      label: (
        <>
          {t('Profile')} <NotificationLabel />
        </>
      ),
      key: '/profile',
      icon: <UserOutlined />,
      onClick: () => {
        navigate('/agpp/profile');
      },
    },
  ];
};
