import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import API_FORM from '../../utils/API/ApiForm';
import { AbstractColumns } from '../../utils/Components/AbstractClass/AbstractClassTypes';

type SettingT = Record<string, AbstractColumns>;
export const updateTableSettings = createAsyncThunk(
  'tables/updateSettings',
  async ({
    tableColumns,
    accountProfileId,
  }: {
    tableColumns: SettingT;
    accountProfileId: number;
  }) => {
    const response = await API_FORM.put(
      `account-profile/tables/${accountProfileId}`,
      {
        tableSetting: JSON.stringify(tableColumns),
      }
    );
    return response.data.tables_settings;
  }
);

type InitialState = { settings: SettingT; latestRequestId: null | string };
const initialState: InitialState = {
  settings: {},
  latestRequestId: null,
};

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setTablesSettings: (state, action: PayloadAction<SettingT>) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTableSettings.fulfilled, (state, action) => {
        if (action.meta.requestId === state.latestRequestId) {
          state.settings = JSON.parse(action.payload);
        }
      })
      .addCase(updateTableSettings.pending, (state, action) => {
        state.latestRequestId = action.meta.requestId;
        state.settings = action.meta.arg.tableColumns;
      });
  },
});

export const { setTablesSettings } = tablesSlice.actions;
export default tablesSlice.reducer;
