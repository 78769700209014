import React, { lazy } from 'react';
import {
  elementTypesDataAgcc,
  jointTypesData,
  marksData,
  onceElementTypeAgccProps,
  onceJointTypesProps,
  onceKindOfControlRFIProps,
  onceMarksProps,
  oncePipelineCategoryProps,
  oncePipelineMaterialsProps,
  onceResultsRFIProps,
  onceScopeOfControlRFIProps,
  onceTemperatureProps,
  onceTypesOfControlRFIProps,
  onceTypesOfQualityControlRfiProps,
  onceTypesOfWorkRfiProps,
  onceUnitOfMeasurementProps,
  pipelineCategoryData,
  pipelineMaterialsData,
  resultsRFIData,
  rfiKindOfControlData,
  scopeOfControlRFIData,
  temperatureData,
  typeOfWorkRFIData,
  typesOfControlRFIData,
  typesOfQualityControlRFIData,
  unitsOfMeasureData,
} from '../../components/Admin/Dictionaries/Extra/DictionariesData';
import {
  cvlProductReportStatusesData,
  onceCvlProductReportStatusesProps,
} from '../../components/Admin/Dictionaries/Extra/CvlProductReportStatuses';
import {
  areaAgccData,
  onceAreaAgccProps,
} from '../../components/Admin/Dictionaries/Extra/AreaExtra';
import {
  inputControlJournalStatusesData,
  onceInputControlJournalStatusesProps,
} from '../../components/Admin/Dictionaries/Extra/InputControlJournalStatuses';
import {
  onceWorksNamesProps,
  worksNamesData,
} from '../../components/Admin/Dictionaries/Extra/WorkNames';
import Registry from '../../components/Registry/Registry';
import {
  factoryNumbersData,
  factoryNumbersProps,
} from '../../components/Admin/Dictionaries/Extra/FactoryData';
import {
  onceWorkingCategoryProps,
  workingCategoryData,
} from '../../components/Admin/Dictionaries/Extra/WorkingCategoryData';
import {
  oncePipelineEnvironmentProps,
  pipelineEnvironmentData,
} from '../../components/Admin/Dictionaries/Extra/PipelineEnvironmentData';
import { pipingInstrumentationDiagramData } from '../../components/Admin/Dictionaries/Extra/PipingInstrumentationDiagramData';
import { onceConstructionZonesProps } from '../../components/Workplace/AGCC/Pipeline/ConstructionZone/ConstructionZoneData';
import {
  onceWeldingMethodsAgccProps,
  weldingMethodsAgccData,
} from '../../components/Admin/Dictionaries/Extra/WeldingMethodsData';
import {
  onceWeldingLocationsAgccProps,
  weldingLocationsAgccData,
} from '../../components/Admin/Dictionaries/Extra/WeldingLocationsExtra';
import { qcInspectorsAgccData } from '../../components/Admin/Dictionaries/Components/QcInspectorsData';
import {
  detailTypeData,
  onceDetailTypeProps,
} from '../../components/Admin/Dictionaries/Extra/DetailTypeData';
import {
  consumablesTypeData,
  onceConsumablesTypeProps,
} from '../../components/Admin/Dictionaries/ConsumablesTypeData';
import {
  onceSteelTypeProps,
  steelTypeData,
} from '../../components/Admin/Dictionaries/Extra/SteelTypeData';
import { liftingTransportEquipmentData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/LiftingTransportEquipment'; // eslint-disable-next-line max-len
import { boilerEquipmentData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/BoilerEquipmentData'; // eslint-disable-next-line max-len
import { gasEquipmentData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/GasEquipmentData'; // eslint-disable-next-line max-len
import { oilGasEquipmentData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/OilGasEquipmentData'; // eslint-disable-next-line max-len
import { metallurgicalEquipmentData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/MetallurgicalEquiomentData'; // eslint-disable-next-line max-len
import { chemicalPetrochemicalEquipmentData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/ChemicalPetrochemicalEquipmentData';
import { miningEquipmentData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/MiningEquipment';
import { transportationEquipmentData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/TransportationEquipmentData';
import { buildingConstructionData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/BuildingConstructionData';
import { steelBridgeStructureData } from '../../components/Admin/Dictionaries/Extra/GroupTechnicalDevices/SteelBridgeStructureData';
import {
  statusesBookData,
  statusesBookProps,
} from '../../components/Admin/Dictionaries/Extra/StatusesBookData';
import {
  directionOfWorkData,
  onceDirectionOfWorkProps,
} from '../../components/Admin/Dictionaries/Extra/DirectionOfWorkData';
import {
  disciplineOfWorkData,
  onceDisciplineOfWorkProps,
} from '../../components/Admin/Dictionaries/Extra/DisciplineOfWorkData';
import {
  onceSubDisciplineProps,
  subDisciplineOfWorkData,
} from '../../components/Admin/Dictionaries/Extra/SubDisciplineData';
import {
  groupOfBlockFactorData,
  onceGroupOfBlockFactorProps,
} from '../../components/Admin/Dictionaries/Extra/GroupOfBlockFactorData';
import {
  commentRfiStatusDataAGCC,
  onceCommentRfiStatusProps,
} from '../../components/Admin/Dictionaries/Extra/commentRfiStatusData';
import {
  eleLocationAgccData,
  onceEleLocationAgccProps,
} from '../../components/Admin/Dictionaries/Extra/EleLocationExtra';
import {
  eleStatusAgccData,
  onceEleStatusAgccProps,
} from '../../components/Admin/Dictionaries/Extra/EleStatusExtra';
import {
  aorpiRequestStatusData,
  onceAorpiRequestStatusProps,
} from '../../components/Admin/Dictionaries/AorpiRequestStatusData';
import Profile from '../../components/Workplace/Profile/Profile';

const OnceAdbLog = lazy(
  () => import('../../components/Workplace/AGCC/AdbLog/OnceAdbLog/OnceAdbLog')
);

const OnceEwBasePhase = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/EwBasePhase/OnceEwBasePhase/OnceEwBasePhase'
    )
);
const EwProductReportElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/EwProductReportElement/EwProductReportElement'
    )
);
const EwRfiLog = lazy(
  () => import('../../components/Workplace/AGCC/Electrician/RFI/RFI')
);
const AdbLog = lazy(
  () => import('../../components/Workplace/AGCC/AdbLog/AdbLog')
);
const RegulatoryDocuments = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/RegulatoryDocuments/RegulatoryDocuments'
    )
);
const SupportFollowUp = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TP/SupportFollowUp/SupportFollowUp'
    )
);
const OnceSupportFollowUp = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TP/SupportFollowUp/OnceSupportFollowUp/OnceSupportFollowUp'
    )
);

const EwBasePhase = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/EwBasePhase/EwBasePhase'
    )
);

const TestPackets = lazy(
  () =>
    import('../../components/Workplace/AGCC/Pipeline/TestPackets/TestPackets')
);

const OnceRfi = lazy(
  () =>
    import('../../components/Workplace/AGCC/Electrician/RFI/OnceRFI/OnceRfi')
);

const OnceTestPackets = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TestPackets/OnceTestPackets/OnceTestPackets'
    )
);

const RequestSupplyResponsible = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/AoRPI/RequestSupplyResponsible/RequestSupplyResponsible'
    )
);
const OnceRequestSupplyResponsible = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/AoRPI/RequestSupplyResponsible/OnceRequestSupplyResponsible/OnceRequestSupplyResponsible'
    )
);

const AorpiRequestConcrete = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/AoRPI/Request/AorpiRequestConcrete/AorpiRequestConcrete'
    )
);
const OnceAorpiRequestConcrete = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/AoRPI/Request/AorpiRequestConcrete/OnceAorpiRequestConcrete/OnceAorpiRequestConcrete'
    )
);

const EwStatistic = lazy(
  () =>
    import('../../components/Workplace/AGCC/Electrician/Statistics/Statistic')
);
const WeldlogAnalytics = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Weldlog/AGSP/WeldlogAnalytics/WeldlogAnalytics'
    )
);

const PipelineResponsible = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/PipelineResponsible/PipelineResponsible'
    )
);
const OncePipelineResponsible = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/PipelineResponsible/OncePipelineResponsible/OncePipelineResponsible'
    )
);
const ExecutiveDocumentation = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/ExecutiveDocumentation/ExecutiveDocumentation'
    )
);
const OnceEwProductReportElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/EwProductReportElement/OnceEwProductReportElement/OnceEwProductReportElement'
    )
);
const OnceExecutiveDocumentation = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/ExecutiveDocumentation/OnceExecutiveDocumentation/OnceExecutiveDocumentation'
    )
);
const EdForms = lazy(
  () => import('../../components/Workplace/AGCC/Electrician/EDForms/EdForms')
);
const OnceEdForms = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/EDForms/OnceEdForms/OnceEdForms'
    )
);

const EleTitleMarks = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/TitleMarks/EleTitleMarks'
    )
);

const EleOnceTitleMark = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/TitleMarks/OnceTitleMark/OnceTitleMark'
    )
);

const ProductReportElementsAnalytics = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/ProductReportElementsAnalitycs/ProductReportElementsAnalytics'
    )
);

const BookPipeline = lazy(
  () => import('../../components/Workplace/AGCC/Pipeline/Book/BookPipeline')
);
const OncePipelineBook = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/Book/OnceBook/OncePipelineBook'
    )
);
const ConstructionZone = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/ConstructionZone/ConstructionZone'
    )
);
const BasePhaseWeldlogGroup = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TP/BasePhaseWeldlogGroup/BasePhaseWeldlogGroup'
    )
);
const OnceBasePhaseWeldlogGroup = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TP/BasePhaseWeldlogGroup/OnceBasePhaseWeldlogGroup/OnceBasePhaseWeldlogGroup'
    )
);

const SpecificationsPipeline = lazy(
  () => import('../../components/Workplace/AGCC/Specifications/Specifications')
);
const OnceSpecificationPipeline = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Specifications/OnceSpecificationComponent/OnceSpecificationPipeline'
    )
);
const ProductReportElementPipeline = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/ProductReportElement/ProductReportElementPipeline'
    )
);
const OnceProductReportElementPipeline = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/ProductReportElement/OncePipelineProductReportElementPipeline/OnceProductReportElementPipeline'
    )
);
const ConsumablePipeline = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/Consumable/ConsumablePipeline'
    )
);
const OnceConsumablePipeline = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/Consumable/OnceConsumablePipeline/OnceConsumablePipeline'
    )
);

const WeldlogUgpp = lazy(
  () => import('../../components/Workplace/AGCC/Weldlog/UGPP/WeldlogUgpp')
);
const OnceWeldlogUgpp = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Weldlog/UGPP/OnceWeldlogUgpp/OnceWeldlogUgpp'
    )
);
const WeldlogUgsp = lazy(
  () => import('../../components/Workplace/AGCC/Weldlog/UGSP/WeldlogUgsp')
);
const OnceWeldlogUgsp = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Weldlog/UGSP/OnceWeldlogUgsp/OnceWeldlogUgsp'
    )
);
const WeldersAgcc = lazy(
  () => import('../../components/Workplace/AGCC/Pipeline/Welders/WeldersAgcc')
);
const OnceInspector = lazy(
  () => import('../../components/Admin/Dictionaries/Components/OnceInspector')
);

const OnceWelder = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/Welders/OnceWelder/OnceWelder'
    )
);

const TechnologicalCard = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TechnologicalCard/TechnologicalCard'
    )
);

const OnceTechnologicalCard = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TechnologicalCard/OnceTechnologicalCard/OnceTechnologicalCard'
    )
);
const BasePhaseTp = lazy(
  () =>
    import('../../components/Workplace/AGCC/Pipeline/TP/BasePhase/BasePhaseTp')
);

const OnceBasePhaseTp = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TP/BasePhase/OnceBasePhaseTp/OnceBasePhaseTp'
    )
);
const WeldlogAGSP = lazy(
  () => import('../../components/Workplace/AGCC/Weldlog/AGSP/WeldlogAGSP')
);
const OnceWeldlogAgsp = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Weldlog/AGSP/OnceWeldlogAgsp/OnceWeldlogAgsp'
    )
);
const OncePipingInstrumentationDiagram = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/OncePipingInstrumentationDiagram/OncePipingInstrumentationDiagram'
    )
);
const TpLines = lazy(
  () => import('../../components/Workplace/AGCC/Pipeline/TP/Lines/TpLines')
);

const OnceLinesTp = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TP/Lines/OnceLines/OnceLinesTp'
    )
);
const Isolist = lazy(
  () => import('../../components/Workplace/AGCC/Pipeline/Isolist/Isolist')
);
const OnceIsolist = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/Isolist/OnceIsolist/OnceIsolist'
    )
);

const TitleConstructionZones = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TitleConstructionZones/TitleConstructionZones'
    )
);
const Isobond = lazy(
  () => import('../../components/Workplace/AGCC/Pipeline/Isobond/Isobond')
);
const OnceIsobond = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/Isobond/OnceIsobond/OnceIsobond'
    )
);
const OnceTitleConstructionZones = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Pipeline/TitleConstructionZones/OnceTitleConstructionZones/OnceTitleConstructionZones'
    )
);

const UnitPrice = lazy(
  () =>
    import('../../components/Workplace/AGCC/Electrician/UnitPrice/UnitPrice')
);
const OnceUnitPrice = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/UnitPrice/OnceUnitPrice/OnceUnitPrice'
    )
);
const SpecificationBond = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/WorkingDocumentation/SpecificationBond/SpecificationBond'
    )
);
const OnceSpecificationBond = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/WorkingDocumentation/SpecificationBond/OnceSpecification/OnceSpecificationBond'
    )
);
const TitleMark = lazy(
  () => import('../../components/Workplace/AGCC/Title-mark/Title-mark')
);
const OnceTitleMark = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Title-mark/OnceTitleMark/OnceTitleMark'
    )
);
const Engineers = lazy(
  () =>
    import('../../components/Workplace/AGCC/Electrician/Engineers/Engineers')
);
const OnceEngineer = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/Engineers/OnceEngineer/OnceEngineer'
    )
);
const Specifications = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/WorkingDocumentation/Specifications/Specifications'
    )
);
const OnceSpecification = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/WorkingDocumentation/Specifications/OnceSpecification/OnceSpecification'
    )
);
const PlansAndJournal = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/WorkingDocumentation/PlansAndJournals/PlansAndJournal'
    )
);
const OncePlanAndJournal = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/Electrician/WorkingDocumentation/PlansAndJournals/OncePlanAndJournal/OncePlanAndJournal'
    )
);
const WorkingTypesBond = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/WorkingAmountsRresults/WorkingTypesBond/WorkingTypesBond'
    )
);
const OnceWorkingTypesBond = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/WorkingAmountsRresults/WorkingTypesBond/OnceWorkingTypesBond/OnceWorkingTypesBond'
    )
);

const WorkingAmount = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/WorkingAmountsRresults/WorkingAmount/WorkingAmount'
    )
);
const OnceWorkingAmount = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/WorkingAmountsRresults/WorkingAmount/OnceWorkingAmount/OnceWorkingAmount'
    )
);

const InputControlJournal = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/InputControlJournal/InputControlJournal'
    )
);

const OnceInputControlJournal = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/InputControlJournal/OnceInputControlJournal/OnceInputControlJournal'
    )
);

const MetalStructuresProductReportElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/MetalStructures/MetalStructuresProductReportElement'
    )
);

const OnceMetalStructuresProductReportElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/MetalStructures/OnceMetalStructuresProductReportElement/OnceMetalStructuresProductReportElement'
    )
);

const OnceResponsibleAorpi = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/ResponsibleAorpi/OnceResponsibleAorpi/OnceResponsibleAorpi'
    )
);

const ResponsibleAorpi = lazy(
  () =>
    import('../../components/Workplace/AGCC/ResponsibleAorpi/ResponsibleAorpi')
);

const OnceRfiLog = lazy(
  () => import('../../components/Workplace/AGCC/RFI/Log/OnceRfiLog/OnceRfiLog')
);
const RfiLog = lazy(
  () => import('../../components/Workplace/AGCC/RFI/Log/RfiLog')
);
const Accounts = lazy(() => import('../../components/Admin/Accounts/Accounts'));
const AoRPI = lazy(() => import('../../components/Workplace/AGCC/AoRPI/AoRPI'));
const OnceAoRPI = lazy(
  () => import('../../components/Workplace/AGCC/AoRPI/OnceAoRPI/OnceAoRPIF')
);
const OnceReportElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/CVL/ProductReportElement/OnceProductReportElement/OnceReportElement'
    )
);
const ProductReportElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/CVL/ProductReportElement/ProductReportElement'
    )
);
const Responsible = lazy(
  () => import('../../components/Workplace/AGCC/RFI/Responsible/Responsible')
);
const OnceResponsible = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/RFI/Responsible/OnceResponsibleRFI/OnceResponsible'
    )
);
const TypeOfOrganizations = lazy(
  () => import('../../components/Admin/TypeOfOrganizations/TypeOfOrganizations')
);
const OnceOrganization = lazy(
  () =>
    import(
      '../../components/Admin/Organizations/OneOrganizationComponent/OnceOrganization'
    )
);
const AccountInfo = lazy(
  () => import('../../components/Admin/Accounts/AccountInfo/AccountInfo')
);
const Departments = lazy(
  () => import('../../components/Admin/Departments/Departments')
);
const OnceDepartment = lazy(
  () =>
    import(
      '../../components/Admin/Departments/OnceDepartmentComponent/OnceDepartment'
    )
);
const Positions = lazy(
  () => import('../../components/Admin/Positions/Positions')
);
const OncePosition = lazy(
  () =>
    import(
      '../../components/Admin/Positions/OncePositionComponent/OncePosition'
    )
);
const TitleList = lazy(
  () => import('../../components/Workplace/AGCC/TitleList/TitleList')
);
const OnceTitle = lazy(
  () =>
    import(
      '../../components/Workplace/AGCC/TitleList/OnceTitleComponent/OnceTitle'
    )
);
const OnceRecord = lazy(
  () => import('../../components/Admin/Dictionaries/Components/OnceRecord')
);
const OnceTypeOfOrganization = lazy(
  () =>
    import(
      '../../components/Admin/TypeOfOrganizations/OnceTypeOfOrganization/OnceTypeOfOrganization'
    )
);
const OnceTemperature = lazy(
  () => import('../../components/Admin/Dictionaries/Components/OnceTemperature')
);
const DictionaryComponent = lazy(
  () => import('../../components/Admin/Dictionaries/DictionaryComponent')
);
const Organizations = lazy(
  () => import('../../components/Admin/Organizations/Organizations')
);
const Permissions = lazy(
  () => import('../../components/Super admin/Permissions/Permissions')
);

export const routerAGCC = [
  {
    path: 'organizations',
    // @ts-ignore
    element: <Organizations />,
    elementWrapperProps: {
      name: 'Organizations',
    },
  },
  {
    path: 'organizations/:id',
    element: <OnceOrganization />,
    elementWrapperProps: {
      name: 'Organizations',
    },
  },
  {
    path: 'departments',
    element: <Departments />,
    elementWrapperProps: {
      name: 'Departments',
    },
  },
  {
    path: 'departments/:id',
    element: <OnceDepartment />,
    elementWrapperProps: {
      name: 'Departments',
    },
  },
  {
    path: 'positions',
    element: <Positions />,
    elementWrapperProps: {
      name: 'Positions',
    },
  },
  {
    path: 'positions/:id',
    element: <OncePosition />,
    elementWrapperProps: {
      name: 'Positions',
    },
  },
  {
    path: 'types',
    element: <TypeOfOrganizations />,
    elementWrapperProps: {
      name: 'Organization types',
    },
  },
  {
    path: 'types/:id',
    element: <OnceTypeOfOrganization />,
    elementWrapperProps: {
      name: 'Organization types',
    },
  },
  {
    path: 'accounts',
    element: <Accounts />,
    elementWrapperProps: {
      name: 'Accounts',
    },
  },
  {
    path: 'accounts/:id',
    element: <AccountInfo />,
    elementWrapperProps: {
      name: 'Profile',
    },
  },
  {
    path: 'titles',
    element: <TitleList />,
    elementWrapperProps: {
      name: 'Titles',
      permission: ['TitleListController'],
    },
  },
  {
    path: 'titles/:id',
    element: <OnceTitle />,
    elementWrapperProps: {
      name: 'Titles',
      permission: ['TitleListController'],
    },
  },
  {
    path: 'product-report',
    element: <AoRPI />,
    elementWrapperProps: {
      name: 'Product report',
      permission: ['ProductReportController'],
    },
  },
  {
    path: 'product-report/:id',
    element: <OnceAoRPI />,
    elementWrapperProps: {
      name: 'Product report',
      permission: ['ProductReportController'],
    },
  },
  {
    path: 'cvl-product-report-element',
    element: <ProductReportElement />,
    elementWrapperProps: {
      name: `Product report elements. Pub. civil works`,
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'cvl-product-report-element/:id',
    element: <OnceReportElement />,
    elementWrapperProps: {
      name: 'Product report elements',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'element-types',
    element: <DictionaryComponent dictionaryData={elementTypesDataAgcc} />,
    elementWrapperProps: {
      name: 'Element types',
    },
  },
  {
    path: 'element-types/:id',
    element: <OnceRecord {...onceElementTypeAgccProps} />,
    elementWrapperProps: {
      name: 'Element types',
    },
  },
  {
    path: 'welding-locations',
    element: <DictionaryComponent dictionaryData={weldingLocationsAgccData} />,
    elementWrapperProps: {
      name: 'Welding locations',
    },
  },
  {
    path: 'welding-locations/:id',
    element: <OnceRecord {...onceWeldingLocationsAgccProps} />,
    elementWrapperProps: {
      name: 'Welding locations',
    },
  },
  {
    path: 'joint-types',
    element: <DictionaryComponent dictionaryData={jointTypesData} />,
    elementWrapperProps: {
      name: 'Joint types',
    },
  },
  {
    path: 'joint-types/:id',
    element: <OnceRecord {...onceJointTypesProps} />,
    elementWrapperProps: {
      name: 'Joint types',
    },
  },
  {
    path: 'factory-numbers',
    element: <DictionaryComponent dictionaryData={factoryNumbersData} />,
    elementWrapperProps: {
      name: 'Factory Numbers',
    },
  },
  {
    path: 'factory-numbers/:id',
    element: <OnceRecord {...factoryNumbersProps} />,
    elementWrapperProps: {
      name: 'Factory Numbers',
    },
  },
  {
    path: 'measurement-unit',
    element: <DictionaryComponent dictionaryData={unitsOfMeasureData} />,
    elementWrapperProps: {
      name: 'Units of measure',
    },
  },
  {
    path: 'measurement-unit/:id',
    element: <OnceRecord {...onceUnitOfMeasurementProps} />,
    elementWrapperProps: {
      name: 'Units of measure',
    },
  },
  {
    path: 'temperature',
    element: <DictionaryComponent dictionaryData={temperatureData} />,
    elementWrapperProps: {
      name: 'Temperature',
    },
  },
  {
    path: 'temperature/:id',
    element: <OnceTemperature {...onceTemperatureProps} />,
    elementWrapperProps: {
      name: 'Temperature',
    },
  },
  {
    path: 'pipeline-materials',
    element: <DictionaryComponent dictionaryData={pipelineMaterialsData} />,
    elementWrapperProps: {
      name: 'Pipeline material',
    },
  },
  {
    path: 'pipeline-materials/:id',
    element: <OnceRecord {...oncePipelineMaterialsProps} />,
    elementWrapperProps: {
      name: 'Pipeline material',
    },
  },
  {
    path: 'pipeline-category',
    element: <DictionaryComponent dictionaryData={pipelineCategoryData} />,
    elementWrapperProps: {
      name: 'Pipeline category',
    },
  },
  {
    path: 'pipeline-category/:id',
    element: <OnceRecord {...oncePipelineCategoryProps} />,
    elementWrapperProps: {
      name: 'Pipeline category',
    },
  },
  {
    path: 'rfi-types-of-work',
    element: <DictionaryComponent dictionaryData={typeOfWorkRFIData} />,
    elementWrapperProps: {
      name: 'Types of work',
    },
  },
  {
    path: 'rfi-types-of-work/:id',
    element: <OnceRecord {...onceTypesOfWorkRfiProps} />,
    elementWrapperProps: {
      name: 'Types of work',
    },
  },
  {
    path: 'rfi-types-of-quality-control',
    element: (
      <DictionaryComponent dictionaryData={typesOfQualityControlRFIData} />
    ),
    elementWrapperProps: {
      name: 'Types and methods of control',
    },
  },
  {
    path: 'rfi-types-of-quality-control/:id',
    element: <OnceRecord {...onceTypesOfQualityControlRfiProps} />,
    elementWrapperProps: {
      name: 'Types and methods of control',
    },
  },
  {
    path: 'rfi-types-of-control',
    element: <DictionaryComponent dictionaryData={typesOfControlRFIData} />,
    elementWrapperProps: {
      name: 'Methods of control',
    },
  },
  {
    path: 'rfi-types-of-control/:id',
    element: <OnceRecord {...onceTypesOfControlRFIProps} />,
    elementWrapperProps: {
      name: 'Methods of control',
    },
  },
  {
    path: 'rfi-result',
    element: <DictionaryComponent dictionaryData={resultsRFIData} />,
    elementWrapperProps: {
      name: 'RFI results',
    },
  },
  {
    path: 'rfi-result/:id',
    element: <OnceRecord {...onceResultsRFIProps} />,
    elementWrapperProps: {
      name: 'RFI results',
    },
  },
  {
    path: 'rfi-scope-of-control',
    element: <DictionaryComponent dictionaryData={scopeOfControlRFIData} />,
    elementWrapperProps: {
      name: 'Scope of control',
    },
  },
  {
    path: 'rfi-scope-of-control/:id',
    element: <OnceRecord {...onceScopeOfControlRFIProps} />,
    elementWrapperProps: {
      name: 'Scope of control',
    },
  },
  {
    path: 'rfi-responsible',
    element: <Responsible />,
    elementWrapperProps: {
      name: 'RFI responsible',
      permission: ['RfiResponsibleController'],
    },
  },
  {
    path: 'rfi-responsible/:id',
    element: <OnceResponsible />,
    elementWrapperProps: {
      name: 'RFI responsible',
      permission: ['RfiResponsibleController'],
    },
  },
  {
    path: 'aorpi-responsible',
    element: <ResponsibleAorpi />,
    elementWrapperProps: {
      name: 'AoRPI responsible',
      permission: ['ProductReportResponsibleController'],
    },
  },
  {
    path: 'aorpi-responsible/:id',
    element: <OnceResponsibleAorpi />,
    elementWrapperProps: {
      name: 'AoRPI responsible',
      permission: ['ProductReportResponsibleController'],
    },
  },
  {
    path: 'rfi-kind-of-control',
    element: <DictionaryComponent dictionaryData={rfiKindOfControlData} />,
    elementWrapperProps: {
      name: 'Kind of control',
    },
  },
  {
    path: 'rfi-kind-of-control/:id',
    element: <OnceRecord {...onceKindOfControlRFIProps} />,
    elementWrapperProps: {
      name: 'Kind of control',
    },
  },
  {
    path: 'permissions',
    element: <Permissions />,
    elementWrapperProps: {
      name: 'Permissions',
      permission: ['superAdmin'],
    },
  },
  {
    path: 'rfi-log',
    element: <RfiLog />,
    elementWrapperProps: {
      name: 'RFI LOG',
      permission: ['RfiLogController'],
    },
  },
  {
    path: 'rfi-log/:id',
    element: <OnceRfiLog />,
    elementWrapperProps: {
      name: 'Inspections',
      permission: ['RfiLogController'],
    },
  },
  {
    path: 'marks',
    element: <DictionaryComponent dictionaryData={marksData} />,
    elementWrapperProps: {
      name: 'Marks',
    },
  },
  {
    path: 'marks/:id',
    element: <OnceRecord {...onceMarksProps} />,
    elementWrapperProps: {
      name: 'Marks',
    },
  },
  {
    path: 'product-report-statuses',
    element: (
      <DictionaryComponent dictionaryData={cvlProductReportStatusesData} />
    ),
    elementWrapperProps: {
      name: 'Product report statuses',
    },
  },
  {
    path: 'product-report-statuses/:id',
    element: <OnceRecord {...onceCvlProductReportStatusesProps} />,
    elementWrapperProps: {
      name: 'Product report status',
    },
  },
  {
    path: 'area',
    element: <DictionaryComponent dictionaryData={areaAgccData} />,
    elementWrapperProps: {
      name: 'Area',
    },
  },
  {
    path: 'area/:id',
    element: <OnceRecord {...onceAreaAgccProps} />,
    elementWrapperProps: {
      name: 'Area',
    },
  },
  {
    path: 'input-control-journal-statuses',
    element: (
      <DictionaryComponent dictionaryData={inputControlJournalStatusesData} />
    ),
    elementWrapperProps: {
      name: 'Input control journal statuses',
    },
  },
  {
    path: 'input-control-journal-statuses/:id',
    element: <OnceRecord {...onceInputControlJournalStatusesProps} />,
    elementWrapperProps: {
      name: 'Input control journal status',
    },
  },
  {
    path: 'metal-structures-product-report-element',
    element: <MetalStructuresProductReportElement />,
    elementWrapperProps: {
      name: 'Product report elements. Metal structures',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'metal-structures-product-report-element/:id',
    element: <OnceMetalStructuresProductReportElement />,
    elementWrapperProps: {
      name: 'Metal structure',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'input-control-journal',
    element: <InputControlJournal />,
    elementWrapperProps: {
      name: 'Input control journal',
      permission: ['InputControlJournalController'],
    },
  },
  {
    path: 'input-control-journal/:id',
    element: <OnceInputControlJournal />,
    elementWrapperProps: {
      name: 'Input control journal',
      permission: ['InputControlJournalController'],
    },
  },
  {
    path: 'working-types-bond',
    element: <WorkingTypesBond />,
    elementWrapperProps: {
      name: 'Titles of works',
      permission: ['WorkingTypesBondController'],
    },
  },
  {
    path: 'working-types-bond/:id',
    element: <OnceWorkingTypesBond />,
    elementWrapperProps: {
      name: 'Title of works',
      permission: ['WorkingTypesBondController'],
    },
  },
  {
    path: 'works-names',
    element: <DictionaryComponent dictionaryData={worksNamesData} />,
    elementWrapperProps: {
      name: 'Titles of works',
    },
  },
  {
    path: 'works-names/:id',
    element: <OnceRecord {...onceWorksNamesProps} />,
    elementWrapperProps: {
      name: 'Title of works',
    },
  },
  {
    path: 'working-amounts',
    element: <WorkingAmount />,
    elementWrapperProps: {
      name: 'Working volumes',
      permission: ['WorkingAmountsController'],
    },
  },
  {
    path: 'working-amounts/:id',
    element: <OnceWorkingAmount />,
    elementWrapperProps: {
      name: 'Statistic',
      permission: ['WorkingAmountsController'],
    },
  },
  {
    path: 'registry',
    element: <Registry />,
    elementWrapperProps: {
      name: 'Registry',
    },
  },
  {
    path: 'title-mark',
    element: <TitleMark />,
    elementWrapperProps: {
      name: 'Title-marks',
      permission: ['TitleMarkController'],
    },
  },
  {
    path: 'title-mark/:id',
    element: <OnceTitleMark />,
    elementWrapperProps: {
      name: 'Title-mark',
      permission: ['TitleMarkController'],
    },
  },
  {
    path: 'working-categories',
    element: <DictionaryComponent dictionaryData={workingCategoryData} />,
    elementWrapperProps: {
      name: 'Types of work',
      permission: [],
    },
  },
  {
    path: 'working-categories/:id',
    element: <OnceRecord {...onceWorkingCategoryProps} />,
    elementWrapperProps: {
      name: 'Type of work',
      permission: [],
    },
  },
  {
    path: 'ele-engineers',
    element: <Engineers />,
    elementWrapperProps: {
      name: 'Engineers',
      permission: ['EleEngineersController'],
    },
  },
  {
    path: 'ele-engineers/:id',
    element: <OnceEngineer />,
    elementWrapperProps: {
      name: 'Engineer',
      permission: ['EleEngineersController'],
    },
  },
  {
    path: 'ele-location',
    element: <DictionaryComponent dictionaryData={eleLocationAgccData} />,
    elementWrapperProps: {
      name: 'ED Location',
    },
  },
  {
    path: 'ele-location/:id',
    element: <OnceRecord {...onceEleLocationAgccProps} />,
    elementWrapperProps: {
      name: 'ED Location',
    },
  },
  {
    path: 'ele-status',
    element: <DictionaryComponent dictionaryData={eleStatusAgccData} />,
    elementWrapperProps: {
      name: 'ED Status',
    },
  },
  {
    path: 'ele-status/:id',
    element: <OnceRecord {...onceEleStatusAgccProps} />,
    elementWrapperProps: {
      name: 'ED Status',
    },
  },
  {
    path: 'ew-specification',
    element: <Specifications />,
    elementWrapperProps: {
      name: 'Specifications',
      permission: ['EleSpecificationBondController'],
    },
  },
  {
    path: 'ew-specification/:id',
    element: <OnceSpecification />,
    elementWrapperProps: {
      name: 'Specification',
      permission: ['EleSpecificationBondController'],
    },
  },
  {
    path: 'ew-working-documentation',
    element: <PlansAndJournal />,
    elementWrapperProps: {
      name: 'Plans and journals',
      permission: ['EleSpecificationBondController'],
    },
  },
  {
    path: 'ew-working-documentation/:id',
    element: <OncePlanAndJournal />,
    elementWrapperProps: {
      name: 'Plans and journals',
      permission: ['EleSpecificationBondController'],
    },
  },
  {
    path: 'specification-bond',
    element: <SpecificationBond />,
    elementWrapperProps: {
      name: 'Relationship between plans and specifications',
      permission: ['EleSpecificationBondController'],
    },
  },
  {
    path: 'specification-bond/:id',
    element: <OnceSpecificationBond />,
    elementWrapperProps: {
      name: 'Relationship between plans and specifications',
      permission: ['EleSpecificationBondController'],
    },
  },
  {
    path: 'unit-price',
    element: <UnitPrice />,
    elementWrapperProps: {
      name: 'Unit price',
      permission: ['EleUnitPriceController'],
    },
  },
  {
    path: 'unit-price/:id',
    element: <OnceUnitPrice />,
    elementWrapperProps: {
      name: 'Unit price',
      permission: ['EleUnitPriceController'],
    },
  },
  {
    path: 'lines-tp',
    element: <TpLines />,
    elementWrapperProps: {
      name: 'Lines',
      permission: [],
    },
  },
  {
    path: 'lines-tp/:id',
    element: <OnceLinesTp />,
    elementWrapperProps: {
      name: 'Line',
      permission: [],
    },
  },
  {
    path: 'isolist',
    element: <Isolist />,
    elementWrapperProps: {
      name: 'Isometries',
      permission: ['IsoListController'],
    },
  },
  {
    path: 'isolist/:id',
    element: <OnceIsolist />,
    elementWrapperProps: {
      name: 'Isometry',
      permission: ['IsoListController'],
    },
  },
  {
    path: 'pipeline-environment',
    element: (
      <DictionaryComponent
        dictionaryData={pipelineEnvironmentData}
        mass={true}
      />
    ),
    elementWrapperProps: {
      name: 'Pipeline environments',
      permission: ['PipelineEnvironmentController'],
    },
  },
  {
    path: 'pipeline-environment/:id',
    element: <OnceRecord {...oncePipelineEnvironmentProps} />,
    elementWrapperProps: {
      name: 'Pipeline environment',
      permission: ['PipelineEnvironmentController'],
    },
  },
  {
    path: 'construction-zones',
    element: <ConstructionZone />,
    elementWrapperProps: {
      name: 'Construction zones',
      permission: ['ConstructionZoneController'],
    },
  },
  {
    path: 'construction-zones/:id',
    element: <OnceRecord {...onceConstructionZonesProps} />,
    elementWrapperProps: {
      name: 'Construction zone',
      permission: ['ConstructionZoneController'],
    },
  },
  {
    path: 'piping-instrumentation-diagram',
    element: (
      <DictionaryComponent dictionaryData={pipingInstrumentationDiagramData} />
    ),
    elementWrapperProps: {
      name: 'Pipeline equipment diagrams',
      permission: ['PipingInstrumentationDiagramController'],
    },
  },
  {
    path: 'piping-instrumentation-diagram/:id',
    element: <OncePipingInstrumentationDiagram />,
    elementWrapperProps: {
      name: 'Pipeline equipment diagram',
      permission: ['PipingInstrumentationDiagramController'],
    },
  },
  {
    path: 'title-construction-zones-bond',
    element: <TitleConstructionZones />,
    elementWrapperProps: {
      name: 'Title-zones',
      permission: ['TitleConstructionZoneBondController'],
    },
  },
  {
    path: 'title-construction-zones-bond/:id',
    element: <OnceTitleConstructionZones />,
    elementWrapperProps: {
      name: 'Title-zone',
      permission: ['TitleConstructionZoneBondController'],
    },
  },
  {
    path: 'isobond',
    element: <Isobond />,
    elementWrapperProps: {
      name: 'Isobond list',
      permission: ['IsoBondController'],
    },
  },
  {
    path: 'isobond/:id',
    element: <OnceIsobond />,
    elementWrapperProps: {
      name: 'Isobond',
      permission: ['IsoBondController'],
    },
  },
  {
    path: 'basephase-tp',
    element: <BasePhaseTp />,
    elementWrapperProps: {
      name: 'BasePhase',
      permission: [],
    },
  },
  {
    path: 'basephase-tp/:id',
    element: <OnceBasePhaseTp />,
    elementWrapperProps: {
      name: 'BasePhase',
      permission: [],
    },
  },
  {
    path: 'weldlog-agsp',
    element: <WeldlogAGSP />,
    elementWrapperProps: {
      name: 'Weldlog. AGSP',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'weldlog-agsp/:id',
    element: <OnceWeldlogAgsp />,
    elementWrapperProps: {
      name: 'Weldlog. AGSP',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'weldlog-ugpp',
    element: <WeldlogUgpp />,
    elementWrapperProps: {
      name: 'Weldlog. UGPP',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'weldlog-ugpp/:id',
    element: <OnceWeldlogUgpp />,
    elementWrapperProps: {
      name: 'Weldlog. UGPP',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'weldlog-ugsp',
    element: <WeldlogUgsp />,
    elementWrapperProps: {
      name: 'Weldlog. UGSP',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'weldlog-ugsp/:id',
    element: <OnceWeldlogUgsp />,
    elementWrapperProps: {
      name: 'Weldlog. UGSP',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'specification',
    element: <SpecificationsPipeline />,
    elementWrapperProps: {
      name: 'Specifications',
      permission: ['SpecificationController'],
    },
  },
  {
    path: 'specification/:id',
    element: <OnceSpecificationPipeline />,
    elementWrapperProps: {
      name: 'Specification',
      permission: ['SpecificationController'],
    },
  },
  {
    path: 'pipeline-element-aorpi',
    element: <ProductReportElementPipeline />,
    elementWrapperProps: {
      name: 'Product report elements. Pipeline',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'pipeline-element-aorpi/:id',
    element: <OnceProductReportElementPipeline />,
    elementWrapperProps: {
      name: 'Product report element. Pipeline',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'pipeline-consumable',
    element: <ConsumablePipeline />,
    elementWrapperProps: {
      name: 'Consumables. Pipeline',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'pipeline-consumable/:id',
    element: <OnceConsumablePipeline />,
    elementWrapperProps: {
      name: 'Consumable. Pipeline"',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'welder',
    element: <WeldersAgcc />,
    elementWrapperProps: {
      name: 'Welders',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'welder/:id',
    element: <OnceWelder />,
    elementWrapperProps: {
      name: 'Welder',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'welding-method',
    element: <DictionaryComponent dictionaryData={weldingMethodsAgccData} />,
    elementWrapperProps: {
      name: 'Welding methods',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'welding-method/:id',
    element: <OnceRecord {...onceWeldingMethodsAgccProps} />,
    elementWrapperProps: {
      name: 'Welding method',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'qc-inspector',
    element: <DictionaryComponent dictionaryData={qcInspectorsAgccData} />,
    elementWrapperProps: {
      name: 'Inspectors',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'qc-inspector/:id',
    element: <OnceInspector />,
    elementWrapperProps: {
      name: 'Inspector',
      permission: ['getAllWeldlog'],
    },
  },
  {
    path: 'welding-card',
    element: <TechnologicalCard />,
    elementWrapperProps: {
      name: 'Inspectors',
      permission: ['getAllWeldingCard'],
    },
  },
  {
    path: 'welding-card/:id',
    element: <OnceTechnologicalCard />,
    elementWrapperProps: {
      name: 'Inspector',
      permission: ['getWeldingCard'],
    },
  },
  {
    path: 'detail-type',
    element: <DictionaryComponent dictionaryData={detailTypeData} />,
    elementWrapperProps: {
      name: 'Detail types',
      permission: ['getAllDetailType'],
    },
  },
  {
    path: 'detail-type/:id',
    element: <OnceRecord {...onceDetailTypeProps} />,
    elementWrapperProps: {
      name: 'Detail type',
      permission: ['getAllDetailType'],
    },
  },
  {
    path: 'book-pipeline',
    element: <BookPipeline />,
    elementWrapperProps: {
      name: 'Books',
      permission: [],
    },
  },
  {
    path: 'book-pipeline/:id',
    element: <OncePipelineBook />,
    elementWrapperProps: {
      name: 'Book',
      permission: [],
    },
  },
  {
    path: 'book-status-pipeline',
    element: <DictionaryComponent dictionaryData={statusesBookData} />,
    elementWrapperProps: {
      name: 'Statuses book',
      permission: [],
    },
  },
  {
    path: 'book-status-pipeline/:id',
    element: <OnceRecord {...statusesBookProps} />,
    elementWrapperProps: {
      name: 'Status book',
      permission: [],
    },
  },
  {
    path: 'consumable-type',
    element: <DictionaryComponent dictionaryData={consumablesTypeData} />,
    elementWrapperProps: {
      name: 'Consumable types',
      permission: ['getAllConsumableType'],
    },
  },
  {
    path: 'consumable-type/:id',
    element: <OnceRecord {...onceConsumablesTypeProps} />,
    elementWrapperProps: {
      name: 'Consumable type',
      permission: ['getAllConsumableType'],
    },
  },
  {
    path: 'steel-type',
    element: <DictionaryComponent dictionaryData={steelTypeData} />,
    elementWrapperProps: {
      name: 'Steel types',
      permission: ['getAllSteelType'],
    },
  },
  {
    path: 'steel-type/:id',
    element: <OnceRecord {...onceSteelTypeProps} />,
    elementWrapperProps: {
      name: 'Steel type',
      permission: ['getAllSteelType'],
    },
  },
  {
    path: 'lifting-transport-equipment',
    element: (
      <DictionaryComponent dictionaryData={liftingTransportEquipmentData} />
    ),
    elementWrapperProps: {
      name: 'Handling equipments',
    },
  },
  {
    path: 'boiler-equipment',
    element: <DictionaryComponent dictionaryData={boilerEquipmentData} />,
    elementWrapperProps: {
      name: 'Boiler equipments',
    },
  },
  {
    path: 'gas-equipment',
    element: <DictionaryComponent dictionaryData={gasEquipmentData} />,
    elementWrapperProps: {
      name: 'Gas equipments',
    },
  },
  {
    path: 'oil-gas-equipment',
    element: <DictionaryComponent dictionaryData={oilGasEquipmentData} />,
    elementWrapperProps: {
      name: 'Oil and gas production equipments',
    },
  },
  {
    path: 'metallurgical-equipment',
    element: (
      <DictionaryComponent dictionaryData={metallurgicalEquipmentData} />
    ),
    elementWrapperProps: {
      name: 'Metallurgical equipments',
    },
  },
  {
    path: 'chemical-petrochemical-equipment',
    element: (
      <DictionaryComponent
        dictionaryData={chemicalPetrochemicalEquipmentData}
      />
    ),
    elementWrapperProps: {
      name: 'Equipments for chemical, petrochemical, oil refining and explosion-hazardous industries',
    },
  },
  {
    path: 'mining-equipment',
    element: <DictionaryComponent dictionaryData={miningEquipmentData} />,
    elementWrapperProps: {
      name: 'Mining equipments',
    },
  },
  {
    path: 'transportation-equipment',
    element: (
      <DictionaryComponent dictionaryData={transportationEquipmentData} />
    ),
    elementWrapperProps: {
      name: 'Equipments for the transportation of dangerous goods',
    },
  },
  {
    path: 'building-structures',
    element: <DictionaryComponent dictionaryData={buildingConstructionData} />,
    elementWrapperProps: {
      name: 'Building construction',
    },
  },
  {
    path: 'steel-bridge-structures',
    element: <DictionaryComponent dictionaryData={steelBridgeStructureData} />,
    elementWrapperProps: {
      name: 'Steel bridge structures',
    },
  },
  {
    path: 'base-phase-weldlog-group',
    element: <BasePhaseWeldlogGroup />,
    elementWrapperProps: {
      name: 'BasePhase groups',
      permission: ['BasePhaseWeldlogGroupController'],
    },
  },
  {
    path: 'base-phase-weldlog-group/:id',
    element: <OnceBasePhaseWeldlogGroup />,
    elementWrapperProps: {
      name: 'BasePhase group',
      permission: ['BasePhaseWeldlogGroupController'],
    },
  },
  {
    path: 'product-report-elements-analytics',
    element: <ProductReportElementsAnalytics />,
    elementWrapperProps: {
      name: 'Distribution analytics product report elements',
      permission: [],
    },
  },
  {
    path: 'ele-executive',
    element: <ExecutiveDocumentation />,
    elementWrapperProps: {
      name: 'Executive documentation',
      permission: [],
    },
  },
  {
    path: 'ele-executive/:id',
    element: <OnceExecutiveDocumentation />,
    elementWrapperProps: {
      name: 'Executive documentation',
      permission: [],
    },
  },
  {
    path: 'ele-title-marks',
    element: <EleTitleMarks />,
    elementWrapperProps: {
      name: 'Title-marks',
      permission: [],
    },
  },
  {
    path: 'ele-title-marks/:id',
    element: <EleOnceTitleMark />,
    elementWrapperProps: {
      name: 'Title-marks',
      permission: [],
    },
  },
  {
    path: 'ew-base-phase',
    element: <EwBasePhase />,
    elementWrapperProps: {
      name: 'Database',
      permission: [],
    },
  },
  {
    path: 'ew-base-phase/:id',
    element: <OnceEwBasePhase />,
    elementWrapperProps: {
      name: 'DataBase',
      permission: [],
    },
  },
  {
    path: 'ele-ed-forms',
    element: <EdForms />,
    elementWrapperProps: {
      name: 'Executive documentation form',
      permission: [],
    },
  },
  {
    path: 'ele-ed-forms/:id',
    element: <OnceEdForms />,
    elementWrapperProps: {
      name: 'Executive documentation form',
      permission: [],
    },
  },
  {
    path: 'ele-product-report-element',
    element: <EwProductReportElement />,
    elementWrapperProps: {
      name: 'Product report elements',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'ele-product-report-element-input',
    element: <EwProductReportElement />,
    elementWrapperProps: {
      name: 'Product report elements',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'ele-product-report-element/:id',
    element: <OnceEwProductReportElement />,
    elementWrapperProps: {
      name: 'Product report element',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'ele-product-report-element-input/:id',
    element: <OnceEwProductReportElement />,
    elementWrapperProps: {
      name: 'Product report element',
      permission: ['ProductReportElementsController'],
    },
  },
  {
    path: 'ele-rfi-log/:id',
    element: <OnceRfi />,
    elementWrapperProps: {
      name: 'RFI LOG',
      permission: [],
    },
  },
  {
    path: 'ele-rfi-log',
    element: <EwRfiLog />,
    elementWrapperProps: {
      name: 'Inspections',
      permission: [],
    },
  },
  {
    path: 'ele-rfi-comment',
    element: <DictionaryComponent dictionaryData={commentRfiStatusDataAGCC} />,
    elementWrapperProps: {
      name: 'Comments on inspections',
      permission: [],
    },
  },
  {
    path: 'ele-rfi-comment/:id',
    element: <OnceRecord {...onceCommentRfiStatusProps} />,
    elementWrapperProps: {
      name: 'Comment for inspection',
      permission: [],
    },
  },
  {
    path: 'adb-log',
    element: <AdbLog />,
    elementWrapperProps: {
      name: 'ADB LOG',
      permission: [],
    },
  },
  {
    path: 'adb-log/:id',
    element: <OnceAdbLog />,
    elementWrapperProps: {
      name: 'ADB-LOG',
      permission: [],
    },
  },
  {
    path: 'direction-of-work',
    element: <DictionaryComponent dictionaryData={directionOfWorkData} />,
    elementWrapperProps: {
      name: 'Directions of work',
    },
  },
  {
    path: 'direction-of-work/:id',
    element: <OnceRecord {...onceDirectionOfWorkProps} />,
    elementWrapperProps: {
      name: 'Direction of work',
    },
  },
  {
    path: 'discipline-of-work',
    element: <DictionaryComponent dictionaryData={disciplineOfWorkData} />,
    elementWrapperProps: {
      name: 'Disciplines',
    },
  },
  {
    path: 'discipline-of-work/:id',
    element: <OnceRecord {...onceDisciplineOfWorkProps} />,
    elementWrapperProps: {
      name: 'Discipline',
    },
  },
  {
    path: 'sub-discipline-of-work',
    element: <DictionaryComponent dictionaryData={subDisciplineOfWorkData} />,
    elementWrapperProps: {
      name: 'Sub disciplines',
    },
  },
  {
    path: 'sub-discipline-of-work/:id',
    element: <OnceRecord {...onceSubDisciplineProps} />,
    elementWrapperProps: {
      name: 'Sub discipline',
    },
  },
  {
    path: 'group-of-block-factor',
    element: <DictionaryComponent dictionaryData={groupOfBlockFactorData} />,
    elementWrapperProps: {
      name: 'Groups of block factors',
    },
  },
  {
    path: 'group-of-block-factor/:id',
    element: <OnceRecord {...onceGroupOfBlockFactorProps} />,
    elementWrapperProps: {
      name: 'Group of block factors',
    },
  },
  {
    path: 'pipeline-responsible',
    element: <PipelineResponsible />,
    elementWrapperProps: {
      name: 'Responsible_1',
    },
  },
  {
    path: 'pipeline-responsible/:id',
    element: <OncePipelineResponsible />,
    elementWrapperProps: {
      name: 'Responsible',
    },
  },
  {
    path: 'ew-summary',
    element: <EwStatistic />,
    elementWrapperProps: {
      name: 'Summary information',
    },
  },
  {
    path: 'weldlog-analytics',
    element: <WeldlogAnalytics />,
    elementWrapperProps: {
      name: 'Weldlog analytics by ItemCode',
    },
  },
  {
    path: 'material-supply-request-status',
    element: <DictionaryComponent dictionaryData={aorpiRequestStatusData} />,
    elementWrapperProps: {
      name: 'Request statuses',
    },
  },
  {
    path: 'material-supply-request-status/:id',
    element: <OnceRecord {...onceAorpiRequestStatusProps} />,
    elementWrapperProps: {
      name: 'Request status',
    },
  },
  {
    path: 'material-supply-request-concrete',
    element: <AorpiRequestConcrete />,
    elementWrapperProps: {
      name: 'Requests of concrete',
    },
  },
  {
    path: 'material-supply-request-concrete/:id',
    element: <OnceAorpiRequestConcrete />,
    elementWrapperProps: {
      name: 'Request of concrete',
    },
  },
  {
    path: 'material-supply-request-responsibles',
    element: <RequestSupplyResponsible />,
    elementWrapperProps: {
      name: 'Responsible_1',
    },
  },
  {
    path: 'material-supply-request-responsibles/:id',
    element: <OnceRequestSupplyResponsible />,
    elementWrapperProps: {
      name: 'Request of concrete',
    },
  },
  {
    path: 'test-packet',
    element: <TestPackets />,
    elementWrapperProps: {
      name: 'Test packs',
    },
  },
  {
    path: 'test-packet/:id',
    element: <OnceTestPackets />,
    elementWrapperProps: {
      name: 'Test packs',
    },
  },
  {
    path: 'profile',
    element: <Profile />,
    elementWrapperProps: {
      name: 'Profile',
    },
  },
  {
    path: 'regulations',
    element: <RegulatoryDocuments />,
    elementWrapperProps: {
      name: 'Regulatory documents',
    },
  },
  {
    path: 'support-follow-up',
    element: <SupportFollowUp />,
    elementWrapperProps: {
      name: 'Support follow up',
      permission: ['SupportFollowUpController'],
    },
  },
  {
    path: 'support-follow-up/:id',
    element: <OnceSupportFollowUp />,
    elementWrapperProps: {
      permission: ['SupportFollowUpController'],
      name: 'Support follow up',
    },
  },
];
