import { PlusOutlined } from '@ant-design/icons';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../../../utils/Components/AbstractClass/AbstractClassTypes';
import React from 'react';

export const getInitialColumns = (t, name = 'title'): AbstractColumns => [
  {
    title: t('Name_1'),
    dataIndex: name,
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: t('Description'),
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

export const getInitialColumnsElementType = (
  t,
  name = 'title'
): AbstractColumns => [
  {
    title: t('Name_1'),
    dataIndex: name,
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: t('Specification table number'),
    dataIndex: 'specificationTableNumber',
    key: 'specificationTableNumber',
    align: 'center',
    visible: true,
  },
  {
    title: t('Description'),
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

export const getSearchFields = (t, name = 'title') => [
  {
    component: 'Input',
    label: t('Title'),
    name: name,
    placeholder: '',
    required: false,
    span: 8,
  },
];

export const pageHeaderExtraDefault: Array<AbstractClassPageHeaderExtraButtonItem> =
  [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      onClick: {
        type: 'innerFunction',
        func: 'setIsAdd',
        extra: true,
      },
    },
  ];
