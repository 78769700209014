import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';
import FormPipelineEnvironment from '../Components/FormPipelineEnvironment';
import React from 'react';
import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';

const idName = 'id';
const api = 'api/pipeline-environment';
const name = 'title';

const formFields = (t) => {
  return [
    {
      label: t('Code'),
      name: 'code',
      component: 'input',
    },
    {
      label: t('Fluid service'),
      name: 'fluidService',
      component: 'input',
    },
  ];
};

export const getInitialColumns = (t): AbstractColumns => [
  {
    title: t('Name_1'),
    dataIndex: 'title',
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: t('Code'),
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    visible: true,
  },
  {
    title: t('Fluid service'),
    dataIndex: 'fluidService',
    key: 'fluidService',
    align: 'center',
    visible: true,
  },
];

export const getSearchFields = (t) => [
  {
    component: 'Input',
    label: t('Name_1'),
    name: 'title',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'Input',
    label: t('Code'),
    name: 'code',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'Input',
    label: t('Fluid service'),
    name: 'fluidService',
    placeholder: '',
    required: false,
    span: 4,
  },
];

export const pipelineEnvironmentData: DictionaryData = {
  apiMethod: api,
  dataListName: 'pipelineEnvironmentDataList',
  idName: idName,
  pageHeaderTitle: 'Pipeline environments',
  tabNameField: name,
  columnsName: 'PipelineEnvironmentTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupListDeleteId,
  formFields: formFields,
  addComponent: <FormPipelineEnvironment type={'add'} />,
  editComponent: <FormPipelineEnvironment type={'edit'} />,
};

export const oncePipelineEnvironmentProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'pipelineEnvironmentData',
  name: name,
  descriptions: [
    { label: 'Name_1', fieldName: 'title', span: 4 },
    { label: 'Code', fieldName: 'code', span: 4 },
    { label: 'Fluid service', fieldName: 'fluidService', span: 4 },
  ],
  editComponent: <FormPipelineEnvironment type={'edit'} />,
};
