import {
  DictionaryData,
  OnceRecordProps,
} from '../../../../Admin/Dictionaries/DictionariesTypes';
import { getPopupListDeleteId } from '../../../../../utils/Popup/PopupExtra';
import ConstructionZonesForm from './components/ConstructionZonesForm';
import React from 'react';

const idName = 'id';
const api = 'api/construction-zones';
const name = 'number';

export const constructionZoneData: DictionaryData = {
  apiMethod: api,
  dataListName: 'constructionZoneDataList',
  idName: idName,
  pageHeaderTitle: 'Construction zones',
  tabNameField: name,
  columnsName: 'ConstructionZoneTable',
  searchFields: (t) => [
    {
      component: 'Input',
      label: t('Number'),
      name: name,
      placeholder: '',
      required: false,
      span: 4,
    },
  ],
  columns: (t) => [
    {
      title: t('Name_1'),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      visible: true,
    },
    {
      title: t('Number'),
      dataIndex: name,
      key: 'number',
      align: 'center',
      visible: true,
    },
  ],
  name: name,
  addComponent: <ConstructionZonesForm type={'add'} />,
  editComponent: <ConstructionZonesForm type={'edit'} />,
  popupList: getPopupListDeleteId,
};

export const onceConstructionZonesProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'constructionZoneData',
  descriptions: [
    { fieldName: 'number', span: 4, label: 'Number' },
    { fieldName: 'title', span: 4, label: 'Title_1' },
  ],
  editComponent: <ConstructionZonesForm type={'edit'} />,
  name: name,
};
